import { Modal } from "antd";
import failImage from "../../assets/images/transaction-fail.svg";
import "./styles.css";

const FailModal = ({
  modalOpen,
  setModalOpen,
  onOk,
  onCancel,
  title = "Buy Token",
  description = "The transaction is failed due to network issue, please try again.",
  ...rest
}) => {
  return (
    <Modal
      open={modalOpen}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
      rootClassName="buy-token-modal"
      centered
      destroyOnClose={false}
      footer={false}
      maskClosable={false}
      {...rest}
    >
      <div className="flex flex-col items-center gap-5">
        <img src={failImage} alt="icon" />
        <p className="text-[24px] text-[#FFFFFF] font-medium leading-none">
          Transaction Failled
        </p>
        <p className="text-xs text-[#FFFFFF80] text-center">{description}</p>
        <button
          className="text-[#86AFFF] text-base font-medium py-2 px-3 bg-[#86AFFF1A] border border-[#FFFFFF0F] rounded-[8px] hover:bg-[#86AFFF33]"
          onClick={() => setModalOpen(false)}
        >
          Try Again
        </button>
      </div>
    </Modal>
  );
};

export default FailModal;
