import { useDisconnect } from "@web3modal/ethers/react";
import { clearAccount } from "web3-module/presale.web3";
import {
  NETWORKS,
  OZ_PHASE_TO_PRICE_MAPPING,
  OZ_PRICE_PHASE,
  TEST_NETWORKS,
} from "utils/constants";

const { disconnect } = useDisconnect();

export const ClipboardCopy = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    // alert("Copied to Clipboard.");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const shortenAddr = (addr) => {
  if (!addr) return "";
  const first = addr.slice(0, 5);
  const last = addr.slice(-3);
  return first + "..." + last;
};

export const shortenHash = (addr, count) => {
  if (!addr) return "";
  const first = addr.slice(0, count + 2);
  const last = addr.slice(-count);
  return first + "..." + last;
};

export const handleKeyPress = (event) => {
  const charCode = event.charCode || event.keyCode;
  if (charCode === 45 || charCode === 43) {
    event.preventDefault();
  }
};

export const getTimeGap = (targetDate, currentDate) => {
  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - targetDate;

  // Convert time difference to days, hours, minutes, and seconds
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  if (days > 1) return `${days} days ago`;
  else if (days === 1) return `${days} day ago`;
  else if (hours > 1) return `${hours} hours ago`;
  else if (hours === 1) return `${hours} hour ago`;
  else if (minutes > 1) return `${minutes} mins ago`;
  else if (minutes === 1) return `${minutes} min ago`;
};

export const roundValue = (tx, decimal) => {
  const numericPart = tx.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot
  const value = parseFloat(numericPart);
  if (value === 0) return 0;
  else return value.toFixed(decimal);
};

export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
};

export const convertValueFromContract = (value, decimal) => {
  return Number(value).toString().padStart(6, "0") / Math.pow(10, decimal);
};

export const getRankName = (value) => {
  let rankName;
  if (0 <= value && value < 100) {
    rankName = "Omega";
  } else if (100 <= value && value < 1000) {
    rankName = "Delta";
  } else if (1000 <= value && value < 10000) {
    rankName = "Beta";
  } else if (10000 <= value && value < 50000) {
    rankName = "Alpha";
  } else if (50000 <= value) {
    rankName = "Sigma";
  }
  return rankName;
};

export const getNextRankName = (value) => {
  let rankName;
  if (0 <= value && value < 100) {
    rankName = "Delta";
  } else if (100 <= value && value < 1000) {
    rankName = "Beta";
  } else if (1000 <= value && value < 10000) {
    rankName = "Alpha";
  } else if (10000 <= value && value < 50000) {
    rankName = "Sigma";
  } else if (50000 <= value) {
    rankName = undefined;
  }
  return rankName;
};

export const getAmountToLevelup = (value, price) => {
  let amountToLevelUp;
  if (price === 0) return 0;
  if (0 <= value && value < 100) {
    amountToLevelUp = formatNumber((100 - value) / price);
  } else if (100 <= value && value < 1000) {
    amountToLevelUp = formatNumber((1000 - value) / price);
  } else if (1000 <= value && value < 10000) {
    amountToLevelUp = formatNumber((10000 - value) / price);
  } else if (10000 <= value && value < 50000) {
    amountToLevelUp = formatNumber((50000 - value) / price);
  } else if (50000 <= value) {
    amountToLevelUp = undefined;
  }
  return amountToLevelUp;
};

export const getPercentage = (value) => {
  let percentage;
  if (0 <= value && value < 100) {
    // Delta
    percentage = value / 100;
  } else if (100 <= value && value < 1000) {
    // Beta
    percentage = (value - 100) / 900;
  } else if (1000 <= value && value < 10000) {
    // Alpha
    percentage = (value - 1000) / 9000;
  } else if (10000 <= value && value < 50000) {
    // Sigma
    percentage = (value - 10000) / 40000;
  } else if (50000 <= value) {
    percentage = 0;
  }
  return percentage;
};

export const getRankNo = (value) => {
  let rankNo;
  if (0 <= value && value < 100) {
    rankNo = 1;
  } else if (100 <= value && value < 1000) {
    rankNo = 2;
  } else if (1000 <= value && value < 10000) {
    rankNo = 3;
  } else if (10000 <= value && value < 50000) {
    rankNo = 4;
  } else if (50000 <= value) {
    rankNo = 5;
  }
  return rankNo;
};

export const chainId2Name = (chainId) => {
  const network = NETWORKS.find((obj) => obj.chainId === parseInt(chainId, 10));
  if (network) {
    return network.nativeCurrency.name;
  } else {
    return "";
  }
};

export const getStage = (value) => {
  let stage;
  if (value <= OZ_PRICE_PHASE.phase01) stage = "Phase 1";
  else if (value > OZ_PRICE_PHASE.phase01 && value <= OZ_PRICE_PHASE.phase02)
    stage = "Phase 2";
  else if (value > OZ_PRICE_PHASE.phase02 && value <= OZ_PRICE_PHASE.phase03)
    stage = "Phase 3";
  else if (value > OZ_PRICE_PHASE.phase03 && value <= OZ_PRICE_PHASE.phase04)
    stage = "Phase 4";
  else if (value > OZ_PRICE_PHASE.phase04 && value <= OZ_PRICE_PHASE.phase05)
    stage = "Phase 5";
  else if (value > OZ_PRICE_PHASE.phase05 && value <= OZ_PRICE_PHASE.phase06)
    stage = "Phase 6";
  else if (value > OZ_PRICE_PHASE.phase06 && value <= OZ_PRICE_PHASE.phase07)
    stage = "Phase 7";
  else if (value > OZ_PRICE_PHASE.phase07 && value <= OZ_PRICE_PHASE.phase08)
    stage = "Phase 8";
  else if (value > OZ_PRICE_PHASE.phase08 && value <= OZ_PRICE_PHASE.phase09)
    stage = "Phase 9";
  else if (value > OZ_PRICE_PHASE.phase09 && value <= OZ_PRICE_PHASE.phase10)
    stage = "Phase 10";
  else if (value > OZ_PRICE_PHASE.phase10 && value <= OZ_PRICE_PHASE.phase11)
    stage = "Phase 11";
  else if (value > OZ_PRICE_PHASE.phase11 && value <= OZ_PRICE_PHASE.phase12)
    stage = "Phase 12";
  else if (value > OZ_PRICE_PHASE.phase12 && value <= OZ_PRICE_PHASE.phase13)
    stage = "Phase 13";
  else if (value > OZ_PRICE_PHASE.phase13 && value <= OZ_PRICE_PHASE.phase14)
    stage = "Phase 14";
  else if (value > OZ_PRICE_PHASE.phase14 && value <= OZ_PRICE_PHASE.phase15)
    stage = "Phase 15";
  else if (value > OZ_PRICE_PHASE.phase15 && value <= OZ_PRICE_PHASE.phase16)
    stage = "Phase 16";
  else if (value > OZ_PRICE_PHASE.phase16 && value <= OZ_PRICE_PHASE.phase17)
    stage = "Phase 17";
  else if (value > OZ_PRICE_PHASE.phase17 && value <= OZ_PRICE_PHASE.phase18)
    stage = "Phase 18";
  else if (value > OZ_PRICE_PHASE.phase18 && value <= OZ_PRICE_PHASE.phase19)
    stage = "Phase 19";
  else if (value > OZ_PRICE_PHASE.phase19 && value <= OZ_PRICE_PHASE.phase20)
    stage = "Phase 20";
  // else if (value > OZ_PRICE_PHASE.listing && value < OZ_PRICE_PHASE.target)
  //   stage = "Launch";
  // else if (value >= OZ_PRICE_PHASE.target && value < OZ_PRICE_PHASE.moon)
  //   stage = "Target";
  // else if (value === OZ_PRICE_PHASE.moon) stage = "Moon";
  return stage;
};

export const findPhaseForPrice = (price) => {
  // Finding an exact match
  const exactMatch = Object.keys(OZ_PHASE_TO_PRICE_MAPPING).find(
    (key) => OZ_PHASE_TO_PRICE_MAPPING[key] === price
  );

  if (exactMatch) {
    return Number(exactMatch); // Return phase for exact match
  }

  // If no exact match, find the closest phase
  const closestPhase = Object.keys(OZ_PHASE_TO_PRICE_MAPPING).reduce(
    (prev, curr) => {
      return Math.abs(OZ_PHASE_TO_PRICE_MAPPING[curr] - price) <
        Math.abs(OZ_PHASE_TO_PRICE_MAPPING[prev] - price)
        ? curr
        : prev;
    }
  );

  return Number(closestPhase); // Return phase for closest match
};

export const toTxArray = (data) => {
  let res = [];
  for (const e of data) {
    let tx = {};
    tx.chainName = chainId2Name(e.chainId);
    tx.tokenAmount = e.tokenAmount;
    tx.value = e.value;
    tx.phase = e.phase;
    tx.date = new Date(e.date).toLocaleString();
    tx.status = e.status;
    tx.hash = e.transactionHash;
    tx.phase = e.phase;
    tx.txType = e.txType;
    res.push(tx);
  }
  // console.log(`toTxArray:::`, res);
  return res;
};

export const getStageFromPrice = (price) => {
  let stage;

  if (OZ_PRICE_PHASE.phase02 > price && price >= OZ_PRICE_PHASE.phase01)
    stage = "Phase 01";
  else if (OZ_PRICE_PHASE.phase03 > price && price >= OZ_PRICE_PHASE.phase02)
    stage = "Phase 02";
  else if (OZ_PRICE_PHASE.phase04 > price && price >= OZ_PRICE_PHASE.phase03)
    stage = "Phase 03";
  else if (OZ_PRICE_PHASE.phase05 > price && price >= OZ_PRICE_PHASE.phase04)
    stage = "Phase 04";
  else if (OZ_PRICE_PHASE.phase06 > price && price >= OZ_PRICE_PHASE.phase05)
    stage = "Phase 05";
  else if (OZ_PRICE_PHASE.phase07 > price && price >= OZ_PRICE_PHASE.phase06)
    stage = "Phase 06";
  else if (OZ_PRICE_PHASE.phase08 > price && price >= OZ_PRICE_PHASE.phase07)
    stage = "Phase 07";
  else if (OZ_PRICE_PHASE.phase09 > price && price >= OZ_PRICE_PHASE.phase08)
    stage = "Phase 08";
  else if (OZ_PRICE_PHASE.phase10 > price && price >= OZ_PRICE_PHASE.phase09)
    stage = "Phase 09";
  else if (OZ_PRICE_PHASE.phase11 > price && price >= OZ_PRICE_PHASE.phase10)
    stage = "Phase 10";
  else if (OZ_PRICE_PHASE.phase12 > price && price >= OZ_PRICE_PHASE.phase11)
    stage = "Phase 11";
  else if (OZ_PRICE_PHASE.phase13 > price && price >= OZ_PRICE_PHASE.phase12)
    stage = "Phase 12";
  else if (OZ_PRICE_PHASE.phase14 > price && price >= OZ_PRICE_PHASE.phase13)
    stage = "Phase 13";
  else if (OZ_PRICE_PHASE.phase15 > price && price >= OZ_PRICE_PHASE.phase14)
    stage = "Phase 14";
  else if (OZ_PRICE_PHASE.phase16 > price && price >= OZ_PRICE_PHASE.phase15)
    stage = "Phase 15";
  else if (OZ_PRICE_PHASE.phase17 > price && price >= OZ_PRICE_PHASE.phase16)
    stage = "Phase 16";
  else if (OZ_PRICE_PHASE.phase18 > price && price >= OZ_PRICE_PHASE.phase17)
    stage = "Phase 17";
  else if (OZ_PRICE_PHASE.phase19 > price && price >= OZ_PRICE_PHASE.phase18)
    stage = "Phase 18";
  else if (OZ_PRICE_PHASE.phase20 > price && price >= OZ_PRICE_PHASE.phase19)
    stage = "Phase 19";
  else if (OZ_PRICE_PHASE.listing > price && price >= OZ_PRICE_PHASE.phase20)
    stage = "Phase 20";
  else if (OZ_PRICE_PHASE.target > price && price >= OZ_PRICE_PHASE.listing)
    stage = "Listing Price";
  else if (OZ_PRICE_PHASE.moon > price && price >= OZ_PRICE_PHASE.target)
    stage = "Target Price";
  else if (price === OZ_PRICE_PHASE.moon) stage = "Moon Price";

  return stage;
};

export const handleLogout = async () => {
  clearAccount();
  await disconnect();
};
