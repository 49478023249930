import Skeleton from "react-loading-skeleton";
import { BASE_COLOR, HIGHLIGHT_COLOR } from "utils/constants";

const DashboardCard = ({
  icon,
  value,
  name,
  handleStartClaim,
  ozBalance,
  showClaim = false,
  setIsClaimTokens,
}) => {
  return (
    <div className="w-full bg-[#FFFFFF1A] border border-[#FFFFFF0F] rounded-[12px] p-[20px] flex justify-between items-center">
      <div className="flex gap-4 justify-start items-center">
        <img src={icon} alt="icon" />
        <div className="flex flex-col gap-3">
          <div className="text-[24px] font-medium leading-none">
            {(ozBalance || ozBalance === 0) && value ? (
              value
            ) : (
              <Skeleton
                width={200}
                height={24}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </div>
          <p className="text-base font-normal text-[#FFFFFF99] leading-none">
            {name}
          </p>
        </div>
      </div>
      {showClaim && (
        <button
          className="bg-white text-black font-semibold rounded-full px-4 py-2 hover:bg-[#FFFFFFCC]"
          onClick={() => {
            handleStartClaim();
            if (name === "Your Referral Earnings") setIsClaimTokens(false);
            else setIsClaimTokens(true);
          }}
        >
          Claim
        </button>
      )}
    </div>
  );
};

export default DashboardCard;
