import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import DashboardIcon from "../../assets/images/dashboard.svg";
import BuyNowIcon from "../../assets/images/buy-now.svg";
import CalcIcon from "../../assets/images/calc.svg";
import LeaderboardIcon from "../../assets/images/leaderboard.svg";
import TransactionIcon from "../../assets/images/transaction.svg";
import UserIcon from "../../assets/images/user.svg";
import OzakAILogo from "../../assets/images/ozak-logo.svg";
import NavTabs from "./NavTab";
import { useMediaQuery } from "react-responsive";
import ArrowRightButton from "components/svgButton/ArrowRightButton";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useAuth } from "contexts/AuthContext";
import {
  convertValueFromContract,
  getRankName,
  getRankNo,
  handleLogout,
} from "utils/functions";
import { POLLING_INTERVAL, BASE_COLOR, HIGHLIGHT_COLOR } from "utils/constants";
import "react-loading-skeleton/dist/skeleton.css";

const images = require.context("../../assets/images/rank/", true);

const NAV_TAB_OPTION = [
  {
    tabName: "Dashboard",
    svgIcon: <img src={DashboardIcon} className="w-[20px] mr-1" />,
    src: "/dashboard",
  },
  {
    tabName: "Buy Now",
    svgIcon: <img src={BuyNowIcon} className="w-[24px]" />,
    src: "/buy",
  },
  {
    tabName: "ROI Calculator",
    svgIcon: <img src={CalcIcon} className="w-[24px]" />,
    src: "/roi-calculator",
  },
  {
    tabName: "Leaderboard",
    svgIcon: <img src={LeaderboardIcon} className="w-[24px]" />,
    src: "/leaderboard",
  },
  {
    tabName: "My Transactions",
    svgIcon: <img src={TransactionIcon} className="w-[24px]" />,
    src: "/transactions",
  },
  {
    tabName: "My Profile",
    svgIcon: <img src={UserIcon} className="w-[20px] mr-1" />,
    src: "/profile",
  },
];

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const [selectedTab, setSelectedTab] = useState(path);
  const { address } = useWeb3ModalAccount();
  const isMobile = useMediaQuery({ maxWidth: 880 });
  const [rankName, setRankName] = useState(undefined);
  const [rankNo, setRankNo] = useState(undefined);
  const { userDetails } = useAuth();

  useEffect(() => {
    if (path) {
      setSelectedTab(path);
    }
  }, [path]);

  const updateData = async () => {
    if (!address || !userDetails) return;
    const _usdInvested = convertValueFromContract(userDetails?.usdInvested, 6);
    setRankName(getRankName(_usdInvested));
    setRankNo(getRankNo(_usdInvested));
  };

  useEffect(() => {
    updateData().catch((err) => console.error(err));
  }, [address, userDetails]);

  return !isMobile ? (
    <div className="w-[232px] min-w-[232px] h-full bg-[#121212] border-r border-[#FFFFFF1A]">
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-4">
          <div className="flex justify-start items-center gap-4 px-4 border-b border-[#FFFFFF1A] h-[87px]">
            <img src={OzakAILogo} className="w-[36px]" alt="logo" />
            <span className="text-[30px] font-[momcake]">OZAK AI</span>
          </div>
          <NavTabs
            tabs={NAV_TAB_OPTION}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isMobile={isMobile}
          />
        </div>
        <div className="bg-[#FFFFFF0A] rounded-xl p-4 m-2 flex flex-col gap-4 items-start">
          <div className="flex gap-2 items-center">
            <div className="flex relative p-[5px]">
              {rankNo ? (
                <p className="text-base text-white rank-no">{rankNo}</p>
              ) : (
                <Skeleton
                  width={32}
                  height={32}
                  circle
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}
              {rankName ? (
                <>
                  <img
                    src={images(`./${rankName.toLowerCase()}.svg`)}
                    alt={rankName.slice(3)}
                    width={32}
                    height={32}
                    className="relative left-[-5px] top-[1px] z-[2px]"
                  />
                </>
              ) : (
                <Skeleton
                  width={32}
                  height={32}
                  circle
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}
            </div>
            {/* <p className="text-base text-[#FFFFFF99] leading-none">Rank:</p> */}
            {rankName ? (
              <p className="text-base text-white">{rankName}</p>
            ) : (
              <Skeleton
                width={60}
                height={22}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </div>
          {/* <p className="text-lg text-white font-medium leading-none">
            {formatContractAddress(address?.toLowerCase()) || (
              <Skeleton
                width={132}
                height={18}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </p> */}
          <button
            className="flex items-center gap-2 pl-0 pr-2 px-3 text-[#5790C5] hover:text-[#5790C5CC]"
            onClick={() => {
              handleLogout();
              navigate("/");
            }}
            disabled={!address}
          >
            Logout
            <ArrowRightButton />
          </button>
        </div>
      </div>
      {/* <WalletConnectButton /> */}
    </div>
  ) : (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-full max-w-[360px] z-[99999]">
      {/* <div className="flex items-center justify-between gap-8 bg-mainBg p-2 rounded-[15px] mx-2 shadow-custom-menu">
        {!showAppNav ? (
          <NavTabs
            tabs={NAV_TAB_OPTION}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isMobile={isMobile}
          />
        ) : (
         <></>
        )}
        <div className="border-l border-[#76808A] flex items-center px-4">
          {!showAppNav ? (
            <img
              src={AppNavMobile}
              className="w-[18px]"
              alt="app nav"
              onClick={() => setShowAppNav(true)}
            />
          ) : (
            <img
              src={Close}
              className="w-[18px]"
              alt="app nav"
              onClick={() => setShowAppNav(false)}
            />
          )}
        </div>
      </div> */}
    </div>
  );
};

export default NavBar;
