import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Carousel } from "antd";
import { toast } from "react-toastify";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import cn from "classnames";
import DashboardCard from "components/dashboard/DashboardCard";
import useScreenResizing from "utils/hooks/useScreenResizing";
import BuyPage from "pages/BuyPage";
import LeaderboardPage from "pages/LeaderboardPage";
import TransactionPage from "pages/TransactionPage";
import ReferralCard from "components/referral/ReferralCard";
import banner1 from "../assets/images/banners/banner1.jpg";
import banner2 from "../assets/images/banners/banner2.jpg";
import banner3 from "../assets/images/banners/banner3.jpg";
import banner4 from "../assets/images/banners/banner4.jpg";
import balanceIcon from "../assets/images/balance.svg";
import worthIcon from "../assets/images/worth.svg";
import earningIcon from "../assets/images/earning.svg";
import {
  formatBalance,
  isReferrerBonusReleased,
  claimReferrerBonus,
  isClaimReleased,
  claimTokens,
  isClaimDurationPassed,
  claimableTokens,
} from "web3-module/presale.web3";
import { useAuth } from "contexts/AuthContext";
import { createTransactionActivity } from "services/api";
import {
  OZ_PRICE_PHASE,
  BASE_COLOR,
  HIGHLIGHT_COLOR,
  TX_MODAL_NAME,
} from "utils/constants";
import {
  convertValueFromContract,
  getNextRankName,
  getRankName,
  getAmountToLevelup,
  getPercentage,
  getRankNo,
} from "utils/functions";
import ClaimStartModal from "components/modal/ClaimStartModal";
import ProgressModal from "components/modal/ProgressModal";
import FailModal from "components/modal/FailModal";
import ClaimSuccessModal from "components/modal/ClaimSuccessModal";
import "react-loading-skeleton/dist/skeleton.css";

const images = require.context("../assets/images/rank/", true);

const DashboardPage = () => {
  const { isMobile, screenLT, screenGT } = useScreenResizing();
  const { address, chainId } = useWeb3ModalAccount();
  const {
    stageInfo,
    userDetails,
    userInfo,
    stageId,
    updateFetchLastestNotifTxnData,
    isInitialized,
  } = useAuth();
  const [ozBalance, setOzBalance] = useState("");
  const [lifeTimeOzBalance, setLifeTimeOzBalance] = useState("");
  const [rankNo, setRankNo] = useState(undefined);
  const [nextRankNo, setNextRankNo] = useState(undefined);
  const [rankName, setRankName] = useState(null);
  const [nextRankName, setNextRankName] = useState(null);
  const [remainingPercentage, setRemainingPercentage] = useState(0);
  const [amountToLevelUp, setAmountToLevelUp] = useState(undefined);
  const [followers, setFollowers] = useState(0);
  const [referralEarning, setReferralEarning] = useState(0);
  const [ozPrice, setOzPrice] = useState(OZ_PRICE_PHASE.phase01);
  const [modalOpen, setModalOpen] = useState(false);
  const [showReferralClaim, setShowReferralClaim] = useState(false);
  const [showTokensClaim, setShowTokensClaim] = useState(false);
  const [isClaimTokens, setIsClaimTokens] = useState(undefined);
  const [tokensClaimed, setTokensClaimed] = useState(0);
  const [referrerEarningsClaimed, setReferrerEarningsClaimed] = useState(0);

  const handleClaimTokens = async () => {
    setModalOpen(TX_MODAL_NAME.progress);
    try {
      // Commented this method because before cliff period 10% of tokens can be claimed so don't need to check this condition
      // const res = await isClaimDurationPassed();
      // if (res) {
      try {
        const response = await claimTokens();
        console.log("claimTokens response:::", response);
        setModalOpen(false);
        setModalOpen(TX_MODAL_NAME.success);
        updateFetchLastestNotifTxnData(true);
        // Comment the apis that insert records from the frontend
        // if (response) {
        //   try {
        //     const res = await createTransactionActivity({
        //       txHash: response.transactionHash,
        //       chainId,
        //       txType: "Tokens Claimed",
        //       phase: stageId,
        //     });
        //     console.log(
        //       "handleClaimTokens createTransactionActivity res:::",
        //       res
        //     );
        //     setModalOpen(TX_MODAL_NAME.success);
        //   } catch (err) {
        //     console.error(
        //       "Failed to create claim tokens transaction activity:::",
        //       err
        //     );
        //     setModalOpen(TX_MODAL_NAME.fail);
        //   }
        //   // setTimeout(() => {
        //   //   setModalOpen(TX_MODAL_NAME.success);
        //   // }, 1000);
        // }
      } catch (err) {
        console.error("Error in claimTokens:::", err);
        setModalOpen(false);
        setTimeout(() => {
          setModalOpen(TX_MODAL_NAME.fail);
        }, 1000);
      }
      // } else {
      //   toast.warn("Presale is not finished yet");
      //   setTimeout(() => {
      //     setModalOpen(false);
      //   }, 1000);
      // }
    } catch (err) {
      console.error("Error in handleClaimTokens:::", err);
      setTimeout(() => {
        setModalOpen(TX_MODAL_NAME.fail);
      }, 1000);
    }
  };

  const handleClaimReferral = async () => {
    setModalOpen(TX_MODAL_NAME.progress);
    try {
      const response = await claimReferrerBonus();
      setModalOpen(false);
      setModalOpen(TX_MODAL_NAME.success);
      updateFetchLastestNotifTxnData(true);
      // Comment the apis that insert records from the frontend
      // if (response) {
      //   try {
      //     const res = await createTransactionActivity({
      //       txHash: response.transactionHash,
      //       chainId,
      //       txType: "Earnings Claimed",
      //       phase: stageId,
      //     });
      //     setModalOpen(TX_MODAL_NAME.success);
      //   } catch (err) {
      //     console.error(
      //       "Failed to create claim referral transaction activity:::",
      //       err
      //     );
      //     setModalOpen(TX_MODAL_NAME.fail);
      //   }
      //   // setTimeout(() => {
      //   //   setModalOpen(TX_MODAL_NAME.success);
      //   // }, 1000);
      // }
    } catch (err) {
      console.error("Error in claimReferrerBonus:::", err);
      setModalOpen(false);
      setTimeout(() => {
        setModalOpen(TX_MODAL_NAME.fail);
      }, 1000);
    }
  };

  const updateData = async () => {
    if (!address) return;

    const _ozPrice = convertValueFromContract(stageInfo?.rate, 6);
    const _tokensAllocated = convertValueFromContract(
      userDetails?.tokensAllocated,
      18
    );
    const _tokensClaimed = convertValueFromContract(
      userDetails?.tokensClaimed,
      18
    );
    setTokensClaimed(_tokensClaimed);
    const _referralEarning = convertValueFromContract(
      userDetails?.referrerEarnings,
      18
    );
    const _referrerEarningsClaimed = convertValueFromContract(
      userDetails?.referrerEarningsClaimed,
      18
    );
    const _ozBalance = _tokensAllocated;
    const _followers = convertValueFromContract(userDetails?.noOfReferrals, 0);
    const _lifeTimeOzBalance = Number(_ozBalance) + Number(_referralEarning);
    setOzBalance(_ozBalance);
    setReferralEarning(_referralEarning);
    setReferrerEarningsClaimed(_referrerEarningsClaimed);
    setOzPrice(_ozPrice);
    setLifeTimeOzBalance(_lifeTimeOzBalance);

    const _usdInvested = convertValueFromContract(userDetails?.usdInvested, 6);
    const _rank = getRankName(_usdInvested);
    const _nextRank = getNextRankName(_usdInvested);
    const _remainingPercentage = getPercentage(_usdInvested);
    const _amountToLevelUp = getAmountToLevelup(_usdInvested, _ozPrice);
    const _rankNo = getRankNo(_usdInvested);

    setRankName(_rank);
    setNextRankName(_nextRank);
    setRankNo(_rankNo);
    setNextRankNo(_rankNo + 1);
    setRemainingPercentage(_remainingPercentage);
    setAmountToLevelUp(_amountToLevelUp);
    setFollowers(_followers);

    try {
      const res = await isClaimReleased();
      // setShowTokensClaim(res && _tokensClaimed === 0);
      if (res) {
        try {
          // const res = await isClaimDurationPassed();
          // if (res) {
          if (_ozBalance > 0) setShowTokensClaim(true);
          // } else {
          //   console.log("Cliff duration not passed");
          // }
        } catch (err) {
          console.error("Error in isClaimDurationPassed:::", err);
        }
      } else {
        // console.log("Presale is not ended");
      }
    } catch (err) {
      console.error("Error in isClaimReleased:::", err);
    }
    try {
      const res = await isReferrerBonusReleased();
      setShowReferralClaim(res && !!_referralEarning);
    } catch (err) {
      console.error("Error in isReferrerBonusReleased:::", err);
    }
  };

  useEffect(() => {
    if (address && isInitialized && userDetails && stageInfo) {
      updateData().catch((err) => console.error(err));
    }
  }, [address, userDetails, stageInfo, isInitialized]);

  return (
    <div className="w-full h-full flex flex-col gap-6">
      {/* <img src={welcomeImg} alt="welcome" /> */}
      <Carousel autoplay>
        <div className="w-full">
          <img src={banner1} alt="Banner 1" className="w-full" />
        </div>
        <div className="w-full">
          <img src={banner2} alt="Banner 2" className="w-full" />
        </div>
        <div className="w-full">
          <img src={banner3} alt="Banner 3" className="w-full" />
        </div>
        <div className="w-full">
          <img src={banner4} alt="Banner 4" className="w-full" />
        </div>
      </Carousel>
      <div className="flex flex-col gap-[36px] bg-[#FFFFFF0D] p-6 rounded-[8px]">
        <div className="flex flex-col justify-start items-center gap-4">
          <p className="text-base leading-none text-[#FFFFFF99]">To Level Up</p>
          {rankNo ? (
            rankNo < 5 ? (
              <p className="text-[24px] leading-none">
                You need to purchase {amountToLevelUp} tokens
              </p>
            ) : (
              <p className="text-[24px] leading-none">
                Congratulations! You have reached to the top level
              </p>
            )
          ) : (
            <Skeleton
              width={330}
              height={24}
              baseColor={BASE_COLOR}
              highlightColor={HIGHLIGHT_COLOR}
            />
          )}
        </div>
        <div className="flex gap-2 justify-between items-center">
          <div className="flex gap-3 items-center">
            {rankName ? (
              <img
                src={images(`./${rankName.toLowerCase()}.svg`)}
                alt={rankName.slice(3)}
              />
            ) : (
              <Skeleton
                width={56}
                height={56}
                circle
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
            <div className="flex flex-col items-start gap-2">
              {rankName ? (
                <p className="text-white font-medium text-lg leading-none">
                  {rankNo}, {rankName}
                </p>
              ) : (
                <Skeleton
                  width={80}
                  height={18}
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}

              <p className="text-[#FFFFFF99] font-normal text-bese leading-none">
                {rankName ? (
                  "Current Rank"
                ) : (
                  <Skeleton
                    width={100}
                    height={16}
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </p>
            </div>
          </div>
          <div
            className={cn("w-[calc(100%-400px)]", {
              "w-[calc(100%-400px)]": rankNo < 5,
              "w-[calc(100%-200px)]": rankNo === 5,
            })}
          >
            <div className="h-[36px] rounded-full p-1 overflow-hidden bg-[#0000008C] border border-[#FFFFFF0F]">
              {remainingPercentage > 0 && (
                <div
                  className="rounded-full h-full border border-[#FFFFFF0F] relative"
                  style={{
                    width: `${remainingPercentage * 100}%`,
                    // backgroundColor: "white",
                    background:
                      "linear-gradient(90deg, #73FFAB 0%, #459967 100%)",
                    // boxShadow: "0px 4px 8px 0px #FFFFFF",
                    boxShadow: "0px 4px 16px 0px #3FCF8A4A",
                  }}
                >
                  <div
                    className="h-6 bg-white rounded-full absolute right-0 text-black flex justify-center items-center font-bold text-lg"
                    style={{
                      width: `${remainingPercentage * 100 < 4 ? "100%" : "24px"}`,
                    }}
                  >
                    {rankNo}
                  </div>
                </div>
              )}
            </div>
          </div>
          {(!rankNo || rankNo < 5) && (
            <div className="flex gap-3 items-center">
              <div className="flex flex-col items-start gap-2">
                <p className="text-[#FFFFFF99] font-normal text-bese leading-none">
                  {nextRankName ? (
                    "Next Rank"
                  ) : (
                    <Skeleton
                      width={100}
                      height={16}
                      baseColor={BASE_COLOR}
                      highlightColor={HIGHLIGHT_COLOR}
                    />
                  )}
                </p>
                {nextRankName ? (
                  <p className="text-white font-medium text-lg leading-none">
                    {nextRankNo}, {nextRankName}
                  </p>
                ) : (
                  <Skeleton
                    width={80}
                    height={18}
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </div>
              {nextRankName ? (
                <img
                  src={images(`./${nextRankName.toLowerCase()}.svg`)}
                  alt={nextRankName.slice(3)}
                />
              ) : (
                <Skeleton
                  width={56}
                  height={56}
                  circle
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
          <DashboardCard
            icon={balanceIcon}
            ozBalance={lifeTimeOzBalance}
            value={
              lifeTimeOzBalance || lifeTimeOzBalance === 0 ? (
                <div className="flex gap-2 flex-wrap">
                  <p className="text-[24px] font-medium leading-none">
                    {formatBalance(lifeTimeOzBalance, 0, true, 3)} OZ
                  </p>
                  <p className="text-[24px] font-medium leading-none text-[#FFFFFF99]">
                    ${formatBalance(lifeTimeOzBalance * ozPrice, 0, true, 3)}
                  </p>
                </div>
              ) : null
            }
            name="Your Lifetime OZ Balance"
          />
          <DashboardCard
            icon={worthIcon}
            ozBalance={ozBalance}
            value={
              ozBalance || ozBalance === 0
                ? `${formatBalance(ozBalance, 0, true, 3)} OZ`
                : null
            }
            name="Total OZ Bought"
            showClaim={showTokensClaim}
            handleStartClaim={() => {
              setModalOpen(TX_MODAL_NAME.start);
            }}
            isClaimTokens={isClaimTokens}
            setIsClaimTokens={setIsClaimTokens}
          />
          <DashboardCard
            icon={earningIcon}
            ozBalance={ozBalance}
            value={
              referralEarning || referralEarning === 0
                ? `${formatBalance(referralEarning, 0, true, 3)} OZ`
                : null
            }
            name="Your Referral Earnings"
            showClaim={showReferralClaim}
            handleStartClaim={() => {
              setModalOpen(TX_MODAL_NAME.start);
            }}
            isClaimTokens={isClaimTokens}
            setIsClaimTokens={setIsClaimTokens}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div
          className={cn("flex justify-between items-stretch gap-6", {
            "flex-row": !screenLT(1440),
            "flex-col": screenLT(1440),
          })}
        >
          <div
            className={cn("", {
              "w-2/5": !screenLT(1440),
              "w-full h-full": screenLT(1440),
            })}
          >
            <BuyPage isFullPage={false} />
          </div>
          <div
            className={cn("", {
              "w-3/5": !screenLT(1440),
              "w-full": screenLT(1440),
            })}
          >
            <LeaderboardPage isFullPage={false} />
          </div>
        </div>
        <div
          className={cn("flex justify-between items-stretch gap-6", {
            "flex-row": !screenLT(1440),
            "flex-col": screenLT(1440),
          })}
        >
          <div
            className={cn("", {
              "w-2/5 ": !screenLT(1440),
              "w-full": screenLT(1440),
            })}
          >
            <TransactionPage isFullPage={false} />
          </div>
          <div
            className={cn("", {
              "w-3/5": !screenLT(1440),
              "w-full": screenLT(1440),
            })}
          >
            <ReferralCard
              isFullPage={false}
              referralEarning={referralEarning}
              followers={followers}
            />
          </div>
        </div>
      </div>
      <ClaimStartModal
        modalOpen={modalOpen === TX_MODAL_NAME.start}
        setModalOpen={setModalOpen}
        ozBalance={isClaimTokens ? ozBalance : referralEarning}
        alreadyClaimed={isClaimTokens ? tokensClaimed : referrerEarningsClaimed}
        referralEarning={referralEarning}
        isClaimTokens={isClaimTokens}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        handleClaim={isClaimTokens ? handleClaimTokens : handleClaimReferral}
      />
      <ProgressModal
        modalOpen={modalOpen === TX_MODAL_NAME.progress}
        setModalOpen={setModalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        title="Claim Token"
      />
      <FailModal
        modalOpen={modalOpen === TX_MODAL_NAME.fail}
        setModalOpen={setModalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        title="Claim Token"
      />
      <ClaimSuccessModal
        modalOpen={modalOpen === TX_MODAL_NAME.success}
        setModalOpen={setModalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      />
    </div>
  );
};

export default DashboardPage;
