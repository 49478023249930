/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import cn from "classnames";
import RankTable from "components/leaderboard/RankTable";
import { formatContractAddress } from "web3-module/presale.web3";
import noTableImage from "../assets/images/notable.svg";
import infoIcon from "../assets/images/info-icon.svg";
import arrowUpRightImage from "../assets/images/arrowupright.svg";
import {
  getRankName,
  getRankNo,
  convertValueFromContract,
} from "utils/functions";
import { getUsersRanking } from "services/api";
import { useAuth } from "contexts/AuthContext";
import {
  POLLING_INTERVAL,
  BASE_COLOR,
  HIGHLIGHT_COLOR,
  PRESALE_CONTRACTS,
} from "utils/constants";
import "react-loading-skeleton/dist/skeleton.css";
import CopyButton from "components/copyButton/CopyButton";
import RankSystemModal from "components/modal/RankSystemModal";
import { Tooltip } from "antd";

const images = require.context("../assets/images/rank/", true);

const LeaderboardPage = ({ isFullPage = true }) => {
  const [totalTx, setTotalTx] = useState(0);
  const [coinHoldings, setCoinHoldings] = useState(undefined);
  const [rankNo, setRankNo] = useState(undefined);
  const [rankName, setRankName] = useState(undefined);
  const [usersRanking, setUsersRanking] = useState([]);
  const [rate, setRate] = useState(null);
  const [openRankSystemModal, setOpenRankSystemModal] = useState(false);
  const { address, chainId } = useWeb3ModalAccount();
  const {
    stageInfo,
    userDetails,
    fetchLatestNotifTxnData,
    updateFetchLastestNotifTxnData,
  } = useAuth();
  const navigate = useNavigate();

  const handleClickCard = () => {
    if (!isFullPage) navigate("/leaderboard");
  };

  const handleSeeAll = () => {
    navigate("/leaderboard");
  };

  const explorerUrl = useMemo(() => {
    return (
      PRESALE_CONTRACTS.find((contract) => contract.chainId === chainId)
        ?.explorerUrl || ""
    );
  }, [chainId]);

  const getActiveUsersSortedWithBalance = (users) => {
    // Filter users with USDBalance greater than 0
    const filteredUsers = users.filter((user) => user.USDBalance > 0);

    // Sort the filtered users by USDBalance in descending order
    const sortedUsers = filteredUsers.sort(
      (a, b) => b.USDBalance - a.USDBalance
    );

    return sortedUsers;
  };

  const updateData = async () => {
    if (!address) return;
    // Fetch users from DB
    const _users = await getUsersRanking();
    let sortedUsers = getActiveUsersSortedWithBalance(_users);
    sortedUsers = isFullPage
      ? sortedUsers.slice(0, 50) // Limit to top 50 users
      : sortedUsers.slice(0, 10); // Limit to top 10 users
    let _usersRanking = [];
    let _rankName;
    let _rankNo;
    // For current user
    const _usdInvested = convertValueFromContract(userDetails?.usdInvested, 6);
    const _coinHoldings = convertValueFromContract(
      userDetails?.tokensAllocated,
      18
    );

    for (const user of sortedUsers) {
      // This usdbalance was coming based on respective phase rate
      // let _totalTransaction = Number(user.USDBalance);
      // Now we need $ value based on the current phase rate
      let _totalTransaction = Number(user.tokenBalance) * rate;
      _rankName = getRankName(_totalTransaction);
      _rankNo = getRankNo(_totalTransaction);
      if (user.walletAddress.toLowerCase() === address.toLowerCase()) {
        setTotalTx(_totalTransaction);
      }
      _usersRanking.push({
        rankName: _rankName,
        rankNo: _rankNo,
        address: user.walletAddress,
        totalTransaction: `$ ${_totalTransaction}`,
        tokenBalance: user.tokenBalance,
      });
    }
    setUsersRanking(_usersRanking);
    setCoinHoldings(_coinHoldings);
    setRankNo(getRankNo(_usdInvested));
    setRankName(getRankName(_usdInvested));
    updateFetchLastestNotifTxnData(false);
  };

  useEffect(() => {
    if (address || fetchLatestNotifTxnData)
      updateData().catch((err) => console.error(err));
  }, [address, userDetails, fetchLatestNotifTxnData, rate]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateData().catch((err) => console.error(err));
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [address, userDetails, rate]);

  useEffect(() => {
    const _rate = stageInfo?.rate || "0";
    setRate(Number(_rate) / 1000000);
  }, [stageInfo]);

  return (
    <>
      <div
        className={cn("flex flex-col gap-8 w-full h-full", {
          "bg-none": isFullPage,
          "bg-[#FFFFFF0A] rounded-[24px] p-6 border border-[#FFFFFF0F] min-h-[600px]":
            !isFullPage,
        })}
        // onClick={handleClickCard}
      >
        <div className="flex items-center gap-2">
          <p className="text-[24px] font-medium text-white leading-none">
            Leaderboard
          </p>
          <Tooltip placement="top" title="Click to view the Ranking System">
            <img
              src={infoIcon}
              width={24}
              className="rotate-180 cursor-pointer"
              onClick={() => setOpenRankSystemModal(!openRankSystemModal)}
            />
          </Tooltip>
        </div>

        <div className="flex flex-col gap-6">
          <div className="rounded-[16px] bg-[#FFFFFF1A] p-6 flex justify-between items-center">
            <div className="flex justify-start items-center gap-6">
              <div className="flex relative p-[5px]">
                {rankNo ? (
                  <p
                    className={cn("leading-none text-white rank-no big", {
                      "text-[32px] font-medium": isFullPage,
                      "text-lg font-normal": !isFullPage,
                    })}
                  >
                    {rankNo}
                  </p>
                ) : (
                  <Skeleton
                    width={48}
                    height={48}
                    circle
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
                {rankName ? (
                  <>
                    <img
                      src={images(`./${rankName.toLowerCase()}.svg`)}
                      alt={rankName}
                      className="relative left-[-15px] top-[1px] z-[2px]"
                    />
                  </>
                ) : (
                  <Skeleton
                    width={56}
                    height={56}
                    circle
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </div>
              <div className="flex flex-col gap-2">
                <p
                  className={cn("leading-none  font-normal text-[#FFFFFF99]", {
                    "text-base": isFullPage,
                    "text-xs": !isFullPage,
                  })}
                >
                  Your Place
                </p>
                {rankName ? (
                  <p
                    className={cn("leading-none text-white", {
                      "text-[32px] font-medium": isFullPage,
                      "text-lg font-normal": !isFullPage,
                    })}
                  >
                    {rankName}
                  </p>
                ) : (
                  <Skeleton
                    width={120}
                    height={32}
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </div>
            </div>
            {isFullPage ? (
              <div className="flex justify-end gap-6">
                <div className="">
                  <p className="text-base font-normal text-[#FFFFFF99] leading-none mb-3">
                    Wallet Address
                  </p>
                  <p className="text-[18px] font-medium text-white leading-none">
                    {address ? (
                      <div className="flex items-baseline gap-2">
                        <a
                          href={`${explorerUrl}/address/${address}`}
                          target="_blank"
                          className="text-base text-[#54A6FF] hover:text-[#54A6FFcc] text-left"
                        >
                          {formatContractAddress(address?.toLowerCase())}
                        </a>
                        <CopyButton
                          copystring={address}
                          btnClassName="!p-1"
                          imgClassName="w-4 h-4"
                        />
                      </div>
                    ) : (
                      <Skeleton
                        width={132}
                        height={18}
                        baseColor={BASE_COLOR}
                        highlightColor={HIGHLIGHT_COLOR}
                      />
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-base font-normal text-[#FFFFFF99] leading-none mb-3">
                    Total transaction
                  </p>
                  <p className="text-[18px] font-medium text-white leading-none">
                    {totalTx || totalTx === 0 ? (
                      <>{totalTx.toLocaleString()} USD</>
                    ) : (
                      <Skeleton
                        width={110}
                        height={18}
                        baseColor={BASE_COLOR}
                        highlightColor={HIGHLIGHT_COLOR}
                      />
                    )}
                  </p>
                </div>
                <div className="">
                  <p className="text-base font-normal text-[#FFFFFF99] leading-none mb-3">
                    Token Holdings
                  </p>
                  <p className="text-[18px] font-medium text-white leading-none">
                    {coinHoldings || coinHoldings === 0 ? (
                      <>{coinHoldings.toLocaleString()} OZ</>
                    ) : (
                      <Skeleton
                        width={110}
                        height={18}
                        baseColor={BASE_COLOR}
                        highlightColor={HIGHLIGHT_COLOR}
                      />
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex gap-4 items-end">
                <p className="text-base font-normal text-[#FFFFFF99]">
                  Total transaction
                </p>
                <p className="text-[18px] font-medium text-white">
                  {totalTx || totalTx === 0 ? (
                    <>$ {totalTx.toLocaleString()}</>
                  ) : (
                    <Skeleton
                      width={40}
                      height={27}
                      baseColor={BASE_COLOR}
                      highlightColor={HIGHLIGHT_COLOR}
                    />
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          className={cn("flex flex-col gap-6", {
            "text-base": isFullPage,
            "text-xs max-h-[calc(100%-200px)]": !isFullPage,
          })}
        >
          {usersRanking.length > 0 ? (
            <RankTable usersRanking={usersRanking} isFullPage={isFullPage} />
          ) : (
            <img src={noTableImage} alt="notable" />
          )}
        </div>
        {!isFullPage && usersRanking.length > 0 && (
          <div className="w-full flex justify-between items-center">
            <p className="text-lg font-medium">See More Records</p>
            <button
              className="p-2 flex justify-center items-center rounded-full bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] border-[#FFFFFF0F]"
              onClick={handleSeeAll}
            >
              <img src={arrowUpRightImage} alt="arrow" />
            </button>
          </div>
        )}
      </div>
      <RankSystemModal
        modalOpen={openRankSystemModal}
        setModalOpen={setOpenRankSystemModal}
        onOk={() => setOpenRankSystemModal(false)}
        onCancel={() => setOpenRankSystemModal(false)}
      />
    </>
  );
};

export default LeaderboardPage;
