import { useState, useEffect } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Button from "components/button/Button";
import Skeleton from "react-loading-skeleton";
import { Slider } from "antd";
import { getStageDetails } from "web3-module/presale.web3";
import {
  OZ_PRICE_PHASE,
  LAUNCH_PRICE,
  BASE_COLOR,
  HIGHLIGHT_COLOR,
  OZ_PRICE_PHASES,
  OZ_PHASE_TO_PRICE_MAPPING,
} from "utils/constants";
import {
  convertValueFromContract,
  findPhaseForPrice,
  getStage,
} from "utils/functions";
import ozakLogo from "../assets/images/ozak-logo.svg";
import { useAuth } from "contexts/AuthContext";
import "./styles.css";
import "react-loading-skeleton/dist/skeleton.css";

const ROIPage = () => {
  const { stageId, stageInfo, userDetails } = useAuth();
  const { address } = useWeb3ModalAccount();
  const [ozBalance, setOzBalance] = useState("");
  const [currentPrice, setCurrentPrice] = useState(null);
  const [sliderValue, setSliderValue] = useState(null);

  const handleChange = (newValue) => {
    setSliderValue(newValue);
  };

  const handleReset = () => {
    setSliderValue(findPhaseForPrice(currentPrice));
  };

  const updateData = async () => {
    if (!address) return;
    const _ozBalance = convertValueFromContract(
      userDetails?.tokensAllocated,
      18
    );
    setOzBalance(_ozBalance?.toLocaleString());
    const _currentPrice = convertValueFromContract(stageInfo?.rate, 6);
    setCurrentPrice(_currentPrice);
  };

  // const tooltipFormatter = (value) => `$ ${value}`;
  const tooltipFormatter = (value) => (
    <div className="flex gap-4">
      <div className="flex flex-col justify-start, items-start">
        <p className="text-[#9BFFB7] text-base font-medium">{`$${OZ_PHASE_TO_PRICE_MAPPING[value]}`}</p>
        <p>{`Phase ${sliderValue}`}</p>
      </div>
      <div className="flex flex-col justify-start, items-start">
        <p className="text-base font-medium">{`$${currentPrice}`}</p>
        <p>Current Price</p>
      </div>
    </div>
  );

  useEffect(() => {
    if (currentPrice) {
      setSliderValue(findPhaseForPrice(currentPrice));
    }
  }, [currentPrice]);

  useEffect(() => {
    updateData().catch((err) => console.error(err));
  }, [address, stageId, stageInfo, userDetails]);

  return (
    <div className="w-full h-full flex justify-center items-center mt-6">
      <div
        className="rounded-[16px] bg-[#55555533] border-[#FFFFFF0F] border flex flex-col min-w-[490px] w-4/5"
        style={{ boxShadow: "-2px 2px 6px 0px #0000000F" }}
      >
        <p className="text-[#FFFFFFE5] text-[24px] p-6">
          Token Amount Calculator
        </p>
        <hr className="bg-[#55555533] border-none h-[2px]" />
        <div className="p-6 flex flex-col gap-7">
          <div>
            <p className="mb-4">How much OZ token you have</p>
            <div className="relative">
              <input
                type="text"
                value={ozBalance}
                className="p-6 flex justify-between items-center bg-[#FFFFFF1A] rounded-xl w-full h-[72px]"
                // onChange={(e) => setOzBalance(e.target.value)}
                readOnly
              />
              <div className="flex justify-start gap-[10px] absolute right-6 top-6">
                <img src={ozakLogo} alt="logo" />
                <p>OZ</p>
              </div>
            </div>
          </div>
          <div>
            <p className="mb-4">USD Amount</p>
            <div className="p-6 flex justify-between items-center bg-[#FFFFFF1A] rounded-xl h-[72px]">
              <p>
                {ozBalance &&
                  sliderValue &&
                  (
                    parseInt(ozBalance.replace(/,/g, ""), 10) *
                    OZ_PHASE_TO_PRICE_MAPPING[sliderValue]
                  ).toLocaleString()}
              </p>
            </div>
          </div>
          <div className="p-6 flex flex-col justify-start items-center bg-[#FFFFFF1A] rounded-xl">
            <div className="flex w-full justify-between">
              <div className="flex flex-col justify-center items-center gap-2 rounded-[8px] border border-[#FFFFFF29] w-[144px] h-[100px] roi-price-container">
                <p className="text-[#9BFFB7] font-medium text-base leading-none">
                  $0.001
                </p>
                <p>Presale Phase 1</p>
              </div>
              <div className="flex flex-col justify-start items-center px-5 w-[calc(100%-310px)] bg-[#00000029] border border-[#FFFFFF29] rounded-[8px]">
                <p className="text-base text-[#FFFFFF99] mt-3 mb-2">
                  Move the slider to see how much your OZ will be worth at
                  different price target
                </p>
                <Slider
                  defaultValue={OZ_PRICE_PHASES.ONE}
                  value={sliderValue}
                  min={OZ_PRICE_PHASES.ONE}
                  max={OZ_PRICE_PHASES.TWENTY}
                  step={OZ_PRICE_PHASES.ONE}
                  onChange={handleChange}
                  autoFocus={true}
                  tooltip={{
                    open: !!currentPrice,
                    placement: "bottom",
                    color: "#505050",
                    formatter: tooltipFormatter,
                  }}
                  rootClassName="slider-tooltip"
                />
              </div>
              <div className="flex flex-col justify-center items-center gap-2 rounded-[8px] border border-[#FFFFFF29] w-[144px] h-[100px] roi-price-container">
                <p className="text-[#9BFFB7] font-medium text-base leading-none">
                  $0.05
                </p>
                <p>Launch Price</p>
              </div>
            </div>
            {/* <hr className="bg-[#FFFFFF1A] w-full border-none h-[2px] mt-10 mb-8" /> */}
            {/* <div className="flex justify-center gap-6 mt-[80px] mb-10">
              <div className="flex flex-col items-start gap-2">
                <p className="text-base leading-none">
                  $ {OZ_PRICE_PHASE.phase01}
                </p>
                <p className="text-xs leading-none text-[#FFFFFF99]">
                  Presale Phase 1
                </p>
              </div>
              <div className="flex flex-col items-start gap-2">
                <p className="text-base leading-none">
                  {currentPrice ? (
                    `$ ${currentPrice}`
                  ) : (
                    <Skeleton
                      width={50}
                      height={16}
                      baseColor={BASE_COLOR}
                      highlightColor={HIGHLIGHT_COLOR}
                    />
                  )}
                </p>
                <p className="text-xs leading-none text-[#FFFFFF99]">
                  Current Price
                </p>
              </div>
              <div className="flex flex-col items-start gap-2">
                <p className="text-base leading-none text-[#73FFAB]">
                  $ {OZ_PRICE_PHASE.listing}
                </p>
                <p className="text-xs leading-none text-[#FFFFFF99]">
                  Launch Price
                </p>
              </div>
            </div> */}
            <Button
              variant="main"
              rounded
              onClick={handleReset}
              className="!py-6 !w-full mt-[100px]"
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROIPage;
