/* eslint-disable */
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ethers, formatUnits } from "ethers";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useWeb3ModalAccount, useSwitchNetwork } from "@web3modal/ethers/react";
import Button from "components/button/Button";
import {
  PRESALE_CONTRACTS,
  ZERO_ADDRESS,
  BASE_COLOR,
  HIGHLIGHT_COLOR,
  TEST_NETWORKS,
} from "utils/constants";
import { useAuth } from "contexts/AuthContext";
import {
  approve,
  buyTokens,
  buyTokensETH,
  getApprovedAmount,
  getBalance,
  getLatestPriceOfETH,
  getWalletBalance,
  isTokenWhitelisted,
  switchNetwork,
} from "web3-module/presale.web3";
import cn from "classnames";
import TokenContainer from "components/buyNow/TokenContainer";
import ozakLogo from "../assets/images/ozak-logo.svg";
import tokensImage from "../assets/images/tokens.svg";
import { TOKENS, TX_MODAL_NAME } from "utils/constants";
import OtherCryptosModal from "components/modal/OtherCryptosModal";
import ProgressModal from "components/modal/ProgressModal";
import SuccessModal from "components/modal/SuccessModal";
import FailModal from "components/modal/FailModal";
import ChangeNetworkModal from "components/modal/ChangeNetworkModal";
import { handleKeyPress } from "../../src/utils/functions";
import { createTransactionActivity, getReferrerAddress } from "services/api";
import "react-loading-skeleton/dist/skeleton.css";
import TnC from "../assets/docs/Terms of Services.pdf";
import Agreement from "../assets/docs/User Consent & Agreement.pdf";

const BuyPage = ({ isFullPage = true }) => {
  const { address, chainId } = useWeb3ModalAccount();
  const {
    minPurchase,
    maxPurchase,
    stageInfo,
    stageId,
    userInfo,
    fetchPresaleInfo,
    referral,
    etherPrice,
    presaleEnded,
    isInitialized,
  } = useAuth();
  const referralLink = `${window.location.origin}/buy?ref=${referral}`;
  const { search } = useLocation();
  const { switchNetwork } = useSwitchNetwork();
  const { updateFetchLastestNotifTxnData } = useAuth();
  const navigate = useNavigate();
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [amount, setAmount] = useState("");
  const [checked, setCheched] = useState(false);
  const [otherCryptosModalOpen, setOtherCryptosModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [threeTokens, setThreeTokens] = useState(null);
  const [phase, setPhase] = useState(null);
  const [totalCoinsSold, setTotalCoinsSold] = useState("");
  const [totalCoinSalesUSD, setTotalCoinSalesUSD] = useState("");
  const [remaining, setRemaining] = useState("");
  const [remainingPercentage, setRemainingPercentage] = useState(0);
  const [walletBalance, setWalletBalance] = useState("");
  const [ozPrice, setOzPrice] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [referrerAddress, setReferrerAddress] = useState(null);
  const [receiveTokens, setReceiveTokens] = useState("0");
  const [rate, setRate] = useState(null);
  const [failDescription, setfailDescription] = useState(
    "The transaction is failed due to network issue, please try again."
  );

  const handleChecked = () => {
    setCheched(!checked);
  };

  const handleMax = async () => {
    if (!selectedToken || !address) return;
    let newAmount = 0;
    newAmount = await getBalance(address, selectedToken.tokenAddress);
    if (selectedToken.tokenAddress === ZERO_ADDRESS) {
      newAmount = Math.max(newAmount - 0.001, 0);
      // Round to avoid floating-point precision issues
      newAmount = Math.floor(newAmount * 10000) / 10000;
    } else newAmount = Number(walletBalance.split(" ")[0]);

    setAmount(newAmount);
    setReceiveTokens(getReceiveTokens(newAmount, rate));
  };

  // const handleClickApply = async () => {
  //   // try {
  //   //   const res = await updateReferral({
  //   //     walletAddress: address,
  //   //     referralCode: referralCode,
  //   //   });
  //   //   if (!res) {
  //   //     toast.warn("Referral code is invalid");
  //   //     return;
  //   //   } else {
  //   //     setReferralCode("");
  //   //     toast.success("Registered");
  //   //     //  return updated userInfo
  //   //     return await getUserInformation(address);
  //   //   }
  //   // } catch (err) {
  //   //   console.error(err);
  //   // }
  //   if (!referralCode) {
  //     toast.warn("Please input referral code");
  //     return;
  //   }
  //   const _referrerAddress = await getReferrerAddress(referralCode);
  //   // console.log("_referrerAddress:::", _referrerAddress);
  //   if (_referrerAddress) {
  //     if (address?.toLowerCase() === _referrerAddress?.toLowerCase()) {
  //       toast.warn("Your referral code is not allowed");
  //       setReferrerAddress(null);
  //     } else {
  //       toast.success("Referral code is valid");
  //       setReferrerAddress(_referrerAddress);
  //     }
  //   } else toast.warn("Referral code is invalid");
  // };

  const checkWhitelist = async (token) => {
    try {
      const res = isTokenWhitelisted(token);
    } catch (err) {
      console.error(err);
    }
  };

  const getReceiveTokens = (usdAmount, price) => {
    // console.log("etherPrice:::", etherPrice);
    let _receiveTokens;
    if (usdAmount === "") return "0";
    if (selectedToken.tokenAddress === ZERO_ADDRESS) {
      _receiveTokens = (
        (Number(etherPrice) * Number(usdAmount)) /
        Number(price)
      ).toLocaleString();
    } else
      _receiveTokens = (Number(usdAmount) / Number(price)).toLocaleString();
    return _receiveTokens;
  };

  const handleClickCard = () => {
    if (!isFullPage) navigate("/buy");
  };

  const buyToken = async () => {
    if (!amount) {
      toast.warn("Please input payment token amount.");
      return;
    }

    const balanceAmount = parseFloat(walletBalance.split(" ")[0]);
    if (amount > balanceAmount) {
      toast.warn("Payment token amount should be less than token balance.");
      return;
    }

    // let hotUserInfo = userInfo;
    // if (referralCode) {
    //   try {
    //     hotUserInfo = await handleClickApply();
    //     if (!hotUserInfo) {
    //       //  referral failed
    //       return;
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     return;
    //   }
    // }
    let _referrerAddress = null;
    try {
      if (referralCode) {
        _referrerAddress = await getReferrerAddress(referralCode);

        if (_referrerAddress) {
          if (address?.toLowerCase() === _referrerAddress?.toLowerCase()) {
            toast.warn("Your referral code is not allowed");
            return;
          } else {
            toast.success("Referral code is valid");
            setReferrerAddress(_referrerAddress);
          }
        } else {
          toast.warn("Referral code is invalid");
          return;
        }
      }
    } catch (err) {
      console.error("Error in getReferrerAddress:::", err);
      return;
    }
    // buy token
    let tx = undefined;
    try {
      // Selected token is native token
      if (selectedToken.tokenAddress === ZERO_ADDRESS) {
        let inputTokenInUSD = 0;
        const _priceOfETH = await getLatestPriceOfETH();
        const priceOfETH = ethers.formatUnits(_priceOfETH, 18);
        inputTokenInUSD = Number(amount) * Number(priceOfETH);
        if (inputTokenInUSD < minPurchase) {
          toast.warn(
            `You must input more than ${minPurchase} USD token amount!`
          );
          return;
        } else if (inputTokenInUSD > maxPurchase) {
          toast.warn(
            `You must input less than ${maxPurchase} USD token amount!`
          );
          return;
        } else {
          setModalOpen(TX_MODAL_NAME.progress);
          try {
            tx = await buyTokensETH(
              Number(amount),
              // hotUserInfo.referrerAddress,
              _referrerAddress
            );
            console.log({ tx });
            if (tx === "Estimate gas error") {
              setModalOpen(false);
              setfailDescription(
                "Insufficient balance. Add more funds or try again with a lower amount."
              );
              setModalOpen(TX_MODAL_NAME.fail);
              return;
            }
            fetchPresaleInfo().catch((err) =>
              console.error("Error in fetchPresaleInfo:::", err)
            );
            setModalOpen(false);
            setModalOpen(TX_MODAL_NAME.success);
            updateFetchLastestNotifTxnData(true);
            // Comment the apis that insert records from the frontend
            // const payload = {
            //   txHash: tx.transactionHash,
            //   chainId,
            //   txType: "Buy Tokens",
            //   phase: stageId,
            // };
            // try {
            //   const res = await createTransactionActivity(payload);
            //   setModalOpen(false);
            //   setModalOpen(TX_MODAL_NAME.success);
            // } catch (err) {
            //   console.error(
            //     "Failed to create buy transaction activity:::",
            //     err
            //   );
            //   setModalOpen(TX_MODAL_NAME.fail);
            // }
          } catch (err) {
            console.error("Error in buyToken:::", err);
            setModalOpen(false);
            setfailDescription(
              "The transaction is failed due to network issue, please try again."
            );
            setModalOpen(TX_MODAL_NAME.fail);
            return;
          }
        }
      }
      // Selected token is stable token
      else {
        if (Number(amount) < minPurchase) {
          toast.warn(
            `You must input more than ${minPurchase} USD token amount!`
          );
          return;
        } else if (Number(amount) > maxPurchase) {
          toast.warn(
            `You must input less than ${maxPurchase} USD token amount!`
          );
          return;
        } else {
          setModalOpen(TX_MODAL_NAME.progress);
          const presaleContractAddress = PRESALE_CONTRACTS.find(
            (obj) => obj.chainId === selectedToken.chainId
          ).address;
          let approvedAmount = await getApprovedAmount(
            selectedToken.tokenAddress,
            address,
            presaleContractAddress
          );
          approvedAmount = Number(
            ethers.formatUnits(approvedAmount, selectedToken.decimal)
          );
          if (approvedAmount < Number(amount)) {
            await approve(selectedToken.tokenAddress, presaleContractAddress);
          }
          const weiAmount = Number(amount) * 10 ** selectedToken.decimal;
          const tokenAddress = selectedToken.tokenAddress;
          try {
            tx = await buyTokens(
              tokenAddress,
              weiAmount,
              // hotUserInfo.referrerAddress,
              _referrerAddress
            );
            console.log({ tx });
            if (tx === "Estimate gas error") {
              setModalOpen(false);
              setfailDescription(
                "Insufficient balance. Add more funds or try again with a lower amount."
              );
              setModalOpen(TX_MODAL_NAME.fail);
              return;
            }
            fetchPresaleInfo().catch((err) =>
              console.error("Error in fetchPresaleInfo:::", err)
            );
            setModalOpen(false);
            setModalOpen(TX_MODAL_NAME.success);
            updateFetchLastestNotifTxnData(true);
            // Comment the apis that insert records from the frontend
            // const payload = {
            //   txHash: tx.transactionHash,
            //   chainId,
            //   txType: "Buy Tokens",
            //   phase: stageId,
            // };
            // try {
            //   const res = await createTransactionActivity(payload);
            //   setModalOpen(false);
            //   setModalOpen(TX_MODAL_NAME.success);
            // } catch (err) {
            //   console.error(
            //     "Failed to create buy transaction activity:::",
            //     err
            //   );
            //   setModalOpen(TX_MODAL_NAME.fail);
            // }
          } catch (err) {
            console.error("Error in buyTokens:::", err);
            setModalOpen(false);
            setfailDescription(
              "The transaction is failed due to network issue, please try again."
            );
            setModalOpen(TX_MODAL_NAME.fail);
            return;
          }
        }
      }
    } catch (err) {
      console.error("Error in getETHPrice:::", err);
      return;
    }
  };

  const updateData = async () => {
    if (!address) return;
    // const _referrerAddress = userInfo?.referrerAddress;
    // setReferrerAddress(_referrerAddress);
    const _tokensSold = stageInfo?.tokensSold || "0";
    const _amountRaised = stageInfo?.amountRaised || "0";
    const _rate = stageInfo?.rate || "0";
    const _tokensAllocated = stageInfo?.tokensAllocated || "0";
    setRate(Number(_rate) / 1000000);
    setOzPrice(`$` + formatUnits(_rate, 6));
    let _totalCoinsSold = "0";
    let _totalCoinSalesUSD = "0";
    let _remaining = "0";
    let _remainingPercentage = 0;
    if (!presaleEnded) {
      _totalCoinsSold = Number(
        ethers.formatEther(_tokensSold)
      ).toLocaleString();
      _totalCoinSalesUSD = Number(
        ethers.formatUnits(_amountRaised, 6)
      ).toLocaleString();
      _remaining = Number(
        ethers.formatEther(_tokensAllocated) - ethers.formatEther(_tokensSold)
      ).toLocaleString();
      _remainingPercentage =
        (Number(_tokensAllocated) - Number(_tokensSold)) /
        Number(_tokensAllocated);
    }
    setTotalCoinsSold(_totalCoinsSold);
    setTotalCoinSalesUSD(_totalCoinSalesUSD);
    setRemaining(_remaining);
    setRemainingPercentage(_remainingPercentage);
    if (stageId) setPhase(stageId);
    // await updateTokens();
  };

  const getTokenByChainId = (chainId) => {
    return TOKENS.find((token) => token.chainId === chainId);
  };

  const updateTokens = async () => {
    if (!address) return;

    const _tokens = TOKENS.filter((obj) => obj.chainId === chainId);
    setTokens(_tokens);

    if (selectedToken) {
      // console.log("selectedToken:::", selectedToken);
      const _balance = await getWalletBalance(
        address,
        selectedToken.tokenAddress,
        chainId
      );
      setWalletBalance(_balance);
      const newThreeTokens = _tokens.slice(0, 3);
      const containsToken = newThreeTokens.some(
        (token) =>
          token.tokenSymbol === selectedToken.tokenSymbol &&
          token.chainName === selectedToken.chainName
      );
      if (!containsToken) {
        const remainingTokens = threeTokens?.filter(
          (token) =>
            token.tokenSymbol !== selectedToken.tokenSymbol &&
            token.chainName === selectedToken.chainName
        );
        // setThreeTokens([selectedToken, ...threeTokens?.slice(0, 2)]);
        setThreeTokens([selectedToken, ...remainingTokens]);
      } else {
        setThreeTokens(_tokens.slice(0, 3));
      }
      await checkWhitelist(selectedToken.tokenAddress);
    } else {
      setThreeTokens(_tokens.slice(0, 3));
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Only allow numbers (and optionally allow decimal points)
    if (!/[-+]/.test(inputValue)) {
      setAmount(inputValue);
      setReceiveTokens(getReceiveTokens(inputValue, rate));
    }
  };

  useEffect(() => {
    // console.log("chainId:::", chainId);
    if (
      !PRESALE_CONTRACTS.some((contract) => contract.chainId === chainId) &&
      chainId
    ) {
      // 28-09-24 - Commenting the switchNetwork because web3modal package itself gives a modal to switch network
      // and if we do as well manually, then chain option in that modal gets disabled
      // setModalOpen(TX_MODAL_NAME.changeNetwork);
      // // Request to switch network to metamask
      // switchNetwork(TEST_NETWORKS[0].chainId).catch((err) =>
      //   console.error(err)
      // );
    } else if (chainId) {
      setModalOpen(false);
      if (getTokenByChainId(chainId).tokenAddress === ZERO_ADDRESS) {
        setSelectedToken(getTokenByChainId(chainId));
      }
    }
  }, [chainId]);

  useEffect(() => {
    if (selectedToken && chainId && isInitialized) {
      updateTokens();
    }
  }, [selectedToken, chainId, isInitialized]);

  useEffect(() => {
    const _referralCode = search.split("?ref=")[1];
    if (_referralCode) {
      setReferralCode(_referralCode);
      localStorage.setItem("referralCode", referralCode);
    }
  }, [search]);

  useEffect(() => {
    updateData().catch((err) => console.error(err));
  }, [
    address,
    stageId,
    userInfo,
    stageInfo,
    chainId,
    minPurchase,
    maxPurchase,
    presaleEnded,
  ]);

  return (
    <div
      className={cn("w-full h-full flex justify-center items-center", {
        "mt-4": isFullPage,
        "mt-0": !isFullPage,
      })}
      // onClick={handleClickCard}
    >
      <div
        className={cn(
          "rounded-[24px] bg-[#FFFFFF0D] border border-[#FFFFFF0F] flex flex-col gap-6 p-6 h-full",
          {
            "bg-none min-w-[200px] w-fit": isFullPage,
            "w-full min-w-0 min-h-[300px]": !isFullPage,
          }
        )}
        style={{ boxShadow: "-2px 2px 6px 0px #0000000F" }}
      >
        <div className="flex justify-between items-start text-[#FFFFFFE5]">
          <p className="text-[#FFFFFFE5] text-[24px] leading-none">Buy Token</p>
          {!presaleEnded &&
            (phase ? (
              <div className="flex justify-between rounded-full px-[14px] py-[8px] bg-[#FFFFFF1A] text-[#FFFFFFE5] text-[12px]">
                Phase {phase}
              </div>
            ) : (
              <Skeleton
                width={77}
                height={34}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
                borderRadius={99}
              />
            ))}
        </div>
        <div className="flex flex-col gap-5 p-6 border border-[#FFFFFF0F] bg-[#FFFFFF1A] rounded-[8px]">
          <div className="flex justify-between">
            {/* <div className="flex flex-col gap-3">
              {totalCoinSalesUSD ? (
                <p className="text-[#73FFAB] text-base font-medium leading-none">
                  {`$ ${totalCoinSalesUSD}`}
                </p>
              ) : (
                <Skeleton
                  width={110}
                  height={16}
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}
              <p className="text-[#FFFFFF99] text-[12px] leading-none">
                Total OZ sold (USD)
              </p>
            </div> */}
            <div className="flex flex-col gap-3">
              {totalCoinsSold ? (
                <p className="text-[#73FFAB] text-base font-medium leading-none text-right">
                  {totalCoinsSold}
                </p>
              ) : (
                <Skeleton
                  width={110}
                  height={16}
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}
              <p className="text-[#FFFFFF99] text-[12px] leading-none">
                Total OZ sold
              </p>
            </div>
          </div>
          <hr className="border-[#FFFFFF1A]" />
          <div className="flex flex-col gap-[6px]">
            <div className="flex justify-between items-center mb-[6px]">
              <span className="text-base text-[#FFFFFF99] leading-none">
                Remaining
              </span>
              <span className="text-base text-white font-medium leading-none">
                {remaining || (
                  <Skeleton
                    width={110}
                    height={16}
                    baseColor={BASE_COLOR}
                    highlightColor={HIGHLIGHT_COLOR}
                  />
                )}
              </span>
            </div>
            <div className="relative h-[20px] p-[2px] rounded-full overflow-hidden bg-[#0000008C] border border-[#FFFFFF0F]">
              <div
                className="rounded-full h-full border border-[#FFFFFF0F]"
                style={{
                  width: `${remainingPercentage * 100}%`,
                  background:
                    "linear-gradient(90deg, #73FFAB 0%, #459967 100%)",
                  boxShadow: "0px 4px 16px 0px #3FCF8A4A",
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex gap-[6px] justify-between items-center">
            <div className="flex gap-[6px]">
              {threeTokens ? (
                threeTokens.map((item, index) => {
                  return (
                    <TokenContainer
                      key={index}
                      token={item}
                      selectedToken={selectedToken}
                      setSelectedToken={setSelectedToken}
                      setAmount={setAmount}
                      setReceiveTokens={setReceiveTokens}
                    />
                  );
                })
              ) : (
                <Skeleton
                  width={290}
                  height={38}
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                  borderRadius={99}
                />
              )}
            </div>
            {/* {tokens.length !== 0 ? (
              <Button
                variant="secondary"
                rounded
                className="!gap-1"
                onClick={() => {
                  setOtherCryptosModalOpen(true);
                }}
              >
                <img src={tokensImage} alt="tokens" />
                <p className="text-base text-[#FFFFFFE5] font-medium">
                  Other Crypto
                </p>
              </Button>
            ) : (
              <Skeleton
                width={200}
                height={38}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
                borderRadius={99}
              />
            )}
            <OtherCryptosModal
              modalOpen={otherCryptosModalOpen}
              setModalOpen={setOtherCryptosModalOpen}
              onOk={() => setOtherCryptosModalOpen(false)}
              onCancel={() => setOtherCryptosModalOpen(false)}
              amount={amount}
              ozPrice={ozPrice}
              tokens={tokens}
              setSelectedToken={setSelectedToken}
            /> */}
          </div>
          <div className="flex flex-col gap-[6px] text-[#FFFFFF99] relative">
            <div className="flex justify-between">
              <span>Enter Amount</span>
              <span>Wallet Bal {walletBalance}</span>
            </div>
            <input
              type="number"
              placeholder="0.0"
              value={amount}
              onKeyPress={handleKeyPress}
              className="bg-[#0000005C] text-white p-5 rounded-[10px] custom-input border border-[#FFFFFF1F]"
              onChange={handleChange}
              disabled={!selectedToken}
            />
            <button
              className="absolute top-[39px] right-3 p-3 bg-[#FFFFFF1A] rounded-[10px] hover:bg-[#FFFFFF33]"
              onClick={handleMax}
              disabled={!address}
            >
              MAX
            </button>
            <div className="flex justify-between mt-1">
              <p className="text-[#FFFFFF99] leading-none">
                You will receive&nbsp;&nbsp;
                <span className="text-[#73FFAB]">{receiveTokens} OZ</span>
              </p>
              {/* <div className="flex justify-center gap-[6px] items-center"> */}
              {/* <img src={ozakLogo} alt="logo" /> */}
              <p className="text-[#FFFFFF99] leading-none">
                OZ Price&nbsp;&nbsp;
                <span className="text-[#73FFAB]">
                  {ozPrice || (
                    <Skeleton
                      width={60}
                      height={16}
                      baseColor={BASE_COLOR}
                      highlightColor={HIGHLIGHT_COLOR}
                    />
                  )}
                </span>
              </p>
              {/* </div> */}
            </div>
          </div>
        </div>
        <label className="checkbox-container cursor-pointer">
          <input
            type="checkbox"
            className="cursor-pointer"
            checked={checked}
            onChange={handleChecked}
          />
          <span className="checkmark"></span>
          <p className="max-w-[499px]">
            Yes, I have read, understood and agreed to the{" "}
            <a
              href={TnC}
              target="_blank"
              className="text-[#54A6FF] hover:text-[#54A6FFcc]"
            >
              Terms of Services
            </a>
            , including the{" "}
            <a
              href={Agreement}
              target="_blank"
              className="text-[#54A6FF] hover:text-[#54A6FFcc]"
            >
              User Consent & Agreement
            </a>
            . By participating in the OZAK AI Token presale, I consent to these
            terms and conditions.
          </p>
        </label>

        {!presaleEnded && (
          <Button
            variant="main"
            rounded
            onClick={buyToken}
            // disabled={isFullPage ? !checked || !address : false}
            disabled={!checked || !address || stageInfo?.isActive === false}
            className="!py-6"
          >
            Buy Token
          </Button>
        )}
        {presaleEnded ? (
          <p className="flex justify-center items-center font-bold text-center">
            Presale is over! Please wait for the next announcement.
          </p>
        ) : (
          stageInfo?.isActive === false && (
            <p className="flex justify-center items-center font-bold">
              Current Phase is not active
            </p>
          )
        )}
        {/* {!referrerAddress &&  (*/}
        <div className="flex flex-col gap-[6px] text-[#FFFFFF99] relative">
          <input
            type="text"
            placeholder="Enter Referral Code"
            value={referralCode}
            className="bg-[#0000005C] p-5 text-white rounded-[10px] custom-input border border-[#FFFFFF1F]"
            onChange={(e) => {
              setReferralCode(e.target.value);
            }}
            disabled={!selectedToken}
          />
          {/* <button
            className="absolute bottom-[14px] right-2 py-2 px-4 text-black bg-[#FFFFFF] rounded-full hover:bg-[#FFFFFFcc]"
            onClick={handleClickApply}
          >
            Apply
          </button> */}
        </div>
        {/* )} */}
        <ProgressModal
          modalOpen={modalOpen === TX_MODAL_NAME.progress}
          setModalOpen={setModalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
        />
        <SuccessModal
          modalOpen={modalOpen === TX_MODAL_NAME.success}
          setModalOpen={setModalOpen}
          referralLink={referralLink}
          onOk={() => {
            setAmount("");
            setModalOpen(false);
          }}
          onCancel={() => {
            setAmount("");
            setModalOpen(false);
          }}
        />
        <FailModal
          modalOpen={modalOpen === TX_MODAL_NAME.fail}
          setModalOpen={setModalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          description={failDescription}
        />
        {/* // 28-09-24 - Commenting the switchNetwork because web3modal package itself gives a modal to switch network
            // and if we do as well manually, then chain option in that modal gets disabled 
        */}
        {/* <ChangeNetworkModal
          modalOpen={modalOpen === TX_MODAL_NAME.changeNetwork}
          setModalOpen={setModalOpen}
          // onOk={() => setModalOpen(false)}
          // onCancel={() => setModalOpen(false)}
        /> */}
      </div>
    </div>
  );
};

export default BuyPage;
