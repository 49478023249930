/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "contexts/AuthContext";
import { formatBalance } from "web3-module/presale.web3";
import {
  getRankName,
  convertValueFromContract,
  handleLogout,
  getRankNo,
} from "utils/functions";
import linkImage from "../assets/images/link.svg";
import logoutImage from "../assets/images/logout.svg";
import ReferralModal from "components/modal/ReferralModal";
import {
  POLLING_INTERVAL,
  BASE_COLOR,
  HIGHLIGHT_COLOR,
  PRESALE_CONTRACTS,
} from "utils/constants";
import "react-loading-skeleton/dist/skeleton.css";
import CopyButton from "components/copyButton/CopyButton";

const images = require.context("../assets/images/rank/", true);

const ProfilePage = () => {
  const { address, chainId } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const { referral, userDetails } = useAuth();
  const referralLink = `${window.location.origin}/buy?ref=${referral}`;
  const [rankNo, setRankNo] = useState(undefined);
  const [rankName, setRankName] = useState("");
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const [followers, setFollowers] = useState(0);
  const [referralEarning, setReferralEarning] = useState(0);

  const handleClickLink = () => {
    setReferralModalOpen(true);
  };

  const updateData = async () => {
    if (!address || !userDetails) return;
    const usdInvested = convertValueFromContract(userDetails?.usdInvested, 6);
    const _followers = convertValueFromContract(userDetails?.noOfReferrals, 0);
    const _referralEarning = convertValueFromContract(
      userDetails?.referrerEarnings,
      18
    );
    setRankName(getRankName(usdInvested));
    setRankNo(getRankNo(usdInvested));

    setReferralEarning(_referralEarning);
    setFollowers(_followers);
  };

  useEffect(() => {
    updateData().catch((err) => console.error(err));
  }, [address, userDetails]);

  const explorerUrl = useMemo(() => {
    return (
      PRESALE_CONTRACTS.find((contract) => contract.chainId === chainId)
        ?.explorerUrl || ""
    );
  }, [chainId]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-[24px] font-medium">My Profile</p>
        {address && (
          <button
            className="text-[#FFFFFF] text-[24px] font-medium flex items-center gap-2 px-3 py-2"
            onClick={() => {
              handleLogout();
              navigate("/");
            }}
            // disabled={!walletAddress}
          >
            Logout
            <img src={logoutImage} alt="link" width={28} height={28} />
          </button>
        )}
      </div>
      {/* Rank */}
      <div className="p-6 bg-[#FFFFFF0D] rounded-[16px] flex justify-between items-center my-6">
        <div className="flex gap-6">
          <div className="flex relative p-[5px]">
            {rankNo ? (
              <p className="leading-none text-white rank-no big text-[32px] font-medium">
                {rankNo}
              </p>
            ) : (
              <Skeleton
                width={48}
                height={48}
                circle
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
            {rankName ? (
              <>
                <img
                  src={images(`./${rankName.toLowerCase()}.svg`)}
                  alt={rankName}
                  className="relative left-[-15px] top-[1px] z-[2px]"
                />
              </>
            ) : (
              <Skeleton
                width={56}
                height={56}
                circle
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </div>
          <div className="flex flex-col justify-center gap-3">
            <p className="text-base text-[#FFFFFF99] leading-none">Rank</p>
            <p className="text-[32px] text-white font-medium leading-none">
              {rankName || (
                <Skeleton
                  width={100}
                  height={32}
                  baseColor={BASE_COLOR}
                  highlightColor={HIGHLIGHT_COLOR}
                />
              )}
            </p>
          </div>
        </div>
        <div className="">
          <p className="text-base text-[#FFFFFF99]">Wallet Address</p>
          <p className="text-lg text-white font-medium">
            {address ? (
              <div className="flex items-baseline gap-2">
                <a
                  href={`${explorerUrl}/address/${address}`}
                  target="_blank"
                  className="text-base text-[#54A6FF] hover:text-[#54A6FFcc] text-left"
                >
                  {address?.toLowerCase()}
                </a>
                <CopyButton
                  copystring={address}
                  btnClassName="!p-1"
                  imgClassName="w-4 h-4"
                />
              </div>
            ) : (
              <Skeleton
                width={400}
                height={24}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </p>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="p-6 bg-[#FFFFFF0D] rounded-[12px] w-1/2">
          <p className="font-medium text-[24px] leading-none mb-3">
            {followers >= 0 ? (
              followers
            ) : (
              <Skeleton
                width={60}
                height={24}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </p>
          <p className="font-normal text-[#FFFFFF99] leading-none">
            Your Referral
          </p>
        </div>
        <div className="p-6 bg-[#FFFFFF0D] rounded-[12px] w-1/2">
          {referralEarning >= 0 ? (
            <p className="font-medium text-[24px] leading-none mb-3">
              {`${formatBalance(referralEarning, 0, true, 3)} OZ`}
            </p>
          ) : (
            <Skeleton
              width={80}
              height={24}
              baseColor={BASE_COLOR}
              highlightColor={HIGHLIGHT_COLOR}
              className="mb-3"
            />
          )}
          <p className="font-normal text-[#FFFFFF99] leading-none">
            Referral Earning
          </p>
        </div>
      </div>
      {/* Referral Link */}
      <div className="p-6 bg-[#FFFFFF0D] rounded-[16px] flex justify-between items-center my-6">
        <div className="flex flex-col gap-3">
          <p className="text-base text-[#FFFFFF99]">Referral Link</p>
          <a
            className="text-[24px] font-medium text-[#4678FF] cursor-pointer"
            href={referralLink}
            target="_blank"
          >
            {referralLink}
          </a>
        </div>
        <div className="flex gap-4">
          <CopyButton copystring={referralLink} />
          <button
            className="p-3 flex justify-center items-center rounded-full bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] border-[#FFFFFF0F]"
            onClick={handleClickLink}
          >
            <img src={linkImage} alt="link" />
          </button>
        </div>
      </div>
      <ReferralModal
        modalOpen={referralModalOpen}
        setModalOpen={setReferralModalOpen}
        onOk={() => setReferralModalOpen(false)}
        onCancel={() => setReferralModalOpen(false)}
        referralLink={referralLink}
      />
    </div>
  );
};

export default ProfilePage;
