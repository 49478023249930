import { useEffect, useState } from "react";
import { Modal } from "antd";
import { toast } from "react-toastify";
import successImage from "../../assets/images/transaction-success.svg";
import Button from "components/button/Button";
import xImage from "../../assets/images/social/x.svg";
import telegramImage from "../../assets/images/social/telegram.svg";
import facebookImage from "../../assets/images/social/facebook.svg";
import whatsappImage from "../../assets/images/social/whatsapp.svg";
import redditImage from "../../assets/images/social/reddit.svg";
import "./styles.css";
import { getReferrerBonusPercentage } from "web3-module/presale.web3";
import { ClipboardCopy } from "utils/functions";

const SuccessModal = ({
  modalOpen,
  setModalOpen,
  onOk,
  onCancel,
  referralLink,
  ...rest
}) => {
  const [referrerPercentage, setReferrerPercentage] = useState(0);

  useEffect(() => {
    if (modalOpen) {
      (async () => {
        const percentage = await getReferrerBonusPercentage();
        setReferrerPercentage(percentage);
      })();
    }
  }, [modalOpen]);

  return (
    <Modal
      open={modalOpen}
      onOk={onOk}
      onCancel={onCancel}
      title="Buy Token"
      rootClassName="buy-token-modal"
      centered
      destroyOnClose={false}
      footer={false}
      maskClosable={false}
      {...rest}
    >
      <div className="flex flex-col items-center gap-5">
        <img src={successImage} alt="icon" />
        <p className="text-[24px] text-[#FFFFFF] font-medium leading-none">
          Your Purchase Was Successful
        </p>
        <p className="text-xs text-[#FFFFFF80] text-center">
          Your OZ tokens will be available for you to claim once the presale
          ends. If you want to earn more, you can start referring your friends
          and earn an instant {referrerPercentage}% from every transaction made
          through your referral link.
        </p>
        <button
          className="text-black text-base font-medium py-2 px-3 bg-[#86AFFF] border border-[#FFFFFF0F] rounded-full hover:bg-[#86AFFFcc]"
          onClick={() => {
            setModalOpen(false);
            toast.info("Copied!");
            ClipboardCopy(referralLink);
          }}
        >
          Copy Referral Link
        </button>
        <p className="text-xs text-[#FFFFFF80] leading-none text-center">
          Share it directly on your social media!
        </p>
        <div
          className="flex gap-4 rounded-full p-3 social-button-group"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0.1) 100%)",
          }}
        >
          <a
            href={`https://twitter.com/intent/tweet?text=Hey%2C%20use%20my%20link%20to%20purchase%20OZ%20using%20this%20link%3A${referralLink}`}
            target="_blank"
          >
            <img
              src={xImage}
              alt="x"
              width={60}
              height={60}
              className="cursor-pointer drop-shadow-x"
            />
          </a>
          <a
            href={`https://telegram.me/share/url?url=Hey%2C%20use%20my%20link%20to%20purchase%20OZ%20using%20this%20link%3A${referralLink}`}
            target="_blank"
          >
            <img
              src={telegramImage}
              alt="telegram"
              width={60}
              height={60}
              className="cursor-pointer drop-shadow-telegram"
            />
          </a>
          <a
            href={`https://www.facebook.com/share.php?u=${referralLink}`}
            target="_blank"
          >
            <img
              src={facebookImage}
              alt="facebook"
              width={60}
              height={60}
              className="cursor-pointer drop-shadow-facebook"
            />
          </a>
          <a
            href={`https://api.whatsapp.com/send?text=Hey,%20use%20my%20link%20to%20purchase%20OZ%20using%20this%20link%3A%20${referralLink}`}
            target="_blank"
          >
            <img
              src={whatsappImage}
              alt="whatsapp"
              width={60}
              height={60}
              className="cursor-pointer drop-shadow-whatsapp"
            />
          </a>
          <a
            href={`https://reddit.com/submit?url=${referralLink}&title=Hey,%20use%20my%20link%20to%20purchase%20OZ%20using%20this%20link%3A`}
            target="_blank"
          >
            <img
              src={redditImage}
              alt="redit"
              width={60}
              height={60}
              className="cursor-pointer drop-shadow-reddit"
            />
          </a>
          {/* <img
            src={copy3dImage}
            alt="copy"
            width={60}
            height={60}
            className="cursor-pointer"
            onClick={() => {
              );
            }}
          /> */}
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
