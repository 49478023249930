import { useNavigate } from "react-router-dom";
import cn from "classnames";
import WalletConnectButton from "components/walletConnect/WalletConnectButton";
import SelectChain from "components/dropdown/SelectChain";
import NavItem from "./NavItem";
import logoutImage from "../../assets/images/logout.svg";
import NotificationDropdown from "components/dropdown/NotificationDropdown";
import { handleLogout } from "utils/functions";

export const Header = ({ isMobile }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full sticky top-0 z-[998] bg-[#121212]">
      <nav className="bg-[#121212] py-[16px] border-b border-[#FFFFFF1A]">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-6">
          <div className="font-medium text-lg">Welcome to OZAK AI 👋</div>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden focus:outline-none hover:bg-gray-700 focus:bg-[#414141]"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 gap-3">
              <li className="!m-0">
                <NotificationDropdown />
              </li>
              <li className="!m-0">
                <button
                  className="p-3 flex justify-center items-center rounded-[10px] bg-[#FFFFFF1A] border border-[#FFFFFF0F] hover:bg-[#FFFFFF4D]"
                  onClick={() => {
                    handleLogout();
                    navigate("/");
                  }}
                >
                  <img src={logoutImage} alt="link" width={28} height={28} />
                </button>
              </li>
              <li className="!m-0">
                <SelectChain />
              </li>
              <li className="!m-0">
                <WalletConnectButton />
              </li>
            </ul>
            {/* <w3m-button /> */}
          </div>
        </div>
      </nav>
    </div>
  );
};
