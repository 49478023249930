import { Modal } from "antd";
import "./styles.css";
const images = require.context("../../assets/images/rank/", true);

const RankSystemModal = ({
  modalOpen,
  setModalOpen,
  onOk,
  onCancel,
  ...rest
}) => {
  return (
    <Modal
      open={modalOpen}
      onOk={onOk}
      onCancel={onCancel}
      title="Rank System"
      rootClassName="buy-token-modal"
      centered
      destroyOnClose={false}
      footer={false}
      maskClosable={false}
      {...rest}
    >
      <div className="flex flex-col items-center gap-5">
        <p className="text-md text-white leading-none text-center">
          Purchase Amount (USD)
        </p>
        <div className="rank-system-table w-full pt-6">
          <table className="min-w-full text-[#FFFFFFB2] rounded-[10px]">
            <thead className="bg-[#00000033] text-xs rounded-[10px]">
              <tr className="w-full">
                <th className="th-text py-4 rounded-tl-[10px] text-left w-[25%]">
                  Rank
                </th>
                <th className="th-text py-4 text-left w-[25%]">Rank Name</th>
                <th className="th-text py-4 text-left w-[25%]">Minimum</th>
                <th className="th-text py-4 rounded-tr-[10px] text-left w-[25%]">
                  Maximum
                </th>
              </tr>
            </thead>
            <tbody className="text-base">
              <tr className="w-full border-[#FFFFFF0F] border-b">
                <td className="py-4 w-[25%] text-left">
                  <div className="flex justify-start items-center relative">
                    <p className="leading-none text-lg text-white rank-no font-normal">
                      1
                    </p>
                    <img
                      src={images(`./omega.svg`)}
                      alt="rank"
                      width={32}
                      height={32}
                      className="relative left-[-5px] top-[1px] z-[2px]"
                    />
                  </div>
                </td>
                <td className="py-4 w-[25%] text-left">Omega</td>
                <td className="py-4 w-[25%] text-left">$0</td>
                <td className="py-4 w-[25%] text-left">$99</td>
              </tr>
              <tr className="w-full border-[#FFFFFF0F] border-b">
                <td className="py-4 w-[25%] text-left">
                  <div className="flex justify-start items-center relative">
                    <p className="leading-none text-lg text-white rank-no font-normal">
                      2
                    </p>
                    <img
                      src={images(`./delta.svg`)}
                      alt="rank"
                      width={32}
                      height={32}
                      className="relative left-[-5px] top-[1px] z-[2px]"
                    />
                  </div>
                </td>
                <td className="py-4 w-[25%] text-left">Delta</td>
                <td className="py-4 w-[25%] text-left">$100</td>
                <td className="py-4 w-[25%] text-left">$999</td>
              </tr>
              <tr className="w-full border-[#FFFFFF0F] border-b">
                <td className="py-4 w-[25%] text-left">
                  <div className="flex justify-start items-center relative">
                    <p className="leading-none text-lg text-white rank-no font-normal">
                      3
                    </p>
                    <img
                      src={images(`./beta.svg`)}
                      alt="rank"
                      width={32}
                      height={32}
                      className="relative left-[-5px] top-[1px] z-[2px]"
                    />
                  </div>
                </td>
                <td className="py-4 w-[25%] text-left">Beta</td>
                <td className="py-4 w-[25%] text-left">$1000</td>
                <td className="py-4 w-[25%] text-left">$9,999</td>
              </tr>
              <tr className="w-full border-[#FFFFFF0F] border-b">
                <td className="py-4 w-[25%] text-left">
                  <div className="flex justify-start items-center relative">
                    <p className="leading-none text-lg text-white rank-no font-normal">
                      4
                    </p>
                    <img
                      src={images(`./alpha.svg`)}
                      alt="rank"
                      width={32}
                      height={32}
                      className="relative left-[-5px] top-[1px] z-[2px]"
                    />
                  </div>
                </td>
                <td className="py-4 w-[25%] text-left">Alpha</td>
                <td className="py-4 w-[25%] text-left">$10,000</td>
                <td className="py-4 w-[25%] text-left">$49,999</td>
              </tr>
              <tr className="w-full border-[#FFFFFF0F] border-b">
                <td className="py-4 w-[25%] text-left">
                  <div className="flex justify-start items-center relative">
                    <p className="leading-none text-lg text-white rank-no font-normal">
                      5
                    </p>
                    <img
                      src={images(`./sigma.svg`)}
                      alt="rank"
                      width={32}
                      height={32}
                      className="relative left-[-5px] top-[1px] z-[2px]"
                    />
                  </div>
                </td>
                <td className="py-4 w-[25%] text-left">Sigma</td>
                <td className="py-4 w-[25%] text-left">$50,000</td>
                <td className="py-4 w-[25%] text-left">Above</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default RankSystemModal;
