// src/components/NavItem.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";

const NavItem = ({ tabName, svgIcon, src, isSelected, onClick, isMobile }) => {
  return (
    <NavLink
      to={src}
      className={cx(
        "flex gap-2 items-center p-3 text-grey cursor-pointer transition-colors duration-300 hover:bg-[#37384780]  rounded-xl",
        {
          "font-normal bg-[#FFFFFF1A]": isSelected,
          // "hover:bg-[#373847]": !isSelected,
        }
      )}
      onClick={onClick}
    >
      {svgIcon}
      {!isMobile && <span className="ml-2 text-sm">{tabName}</span>}
    </NavLink>
  );
};

export default NavItem;
