import { Select } from "antd";
import { useSwitchNetwork, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { NETWORKS, TEST_NETWORKS } from "utils/constants";
import "./styles.css";

const images = require.context("../../assets/images/token", true);

const SelectChain = () => {
  const { chainId } = useWeb3ModalAccount();
  const { switchNetwork } = useSwitchNetwork();

  const handleChange = async (value) => {
    // Fix network as Sepolia
    // const network = TEST_NETWORKS.find((obj) => {
    //   return obj.chainId === value;
    // });
    // const network = TEST_NETWORKS[0];
    const network = NETWORKS.find((obj) => {
      return obj.chainId === value;
    });
    try {
      await switchNetwork(network.chainId);
    } catch (err) {
      console.error("Error in switching network:::", err);
    }
  };

  const chainOptions = (chains) => {
    return chains.map((chain) => ({
      value: chain.chainId,
      label: (
        <div className="flex gap-3 items-center py-[10px]">
          <img
            src={images(`./${chain.nativeCurrency.symbol.toLowerCase()}.svg`)}
            alt={chain.nativeCurrency.name}
            className="w-[24px]"
          />
          <p className="text-base font-medium text-white mr-3 leading-none">
            {chain.chainName}
          </p>
        </div>
      ),
    }));
  };

  return (
    <Select
      value={
        NETWORKS.find((network) => network.chainId === parseInt(chainId, 10))
          ?.chainId
      }
      rootClassName="select-chain"
      onChange={handleChange}
      // options={chainOptions(NETWORKS.slice(0, 1))} // TODO: Change it later if multiple networks are supported
      options={chainOptions(
        NETWORKS.filter(
          (network) => network.chainId === parseInt(chainId, 10)
        ).filter(Boolean)
      )}
    />
  );
};

export default SelectChain;
