const ArrowRightButton = () => {
  return (
    <svg
      width="16"
      height="6"
      viewBox="0 0 16 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.424 5.816L12.304 3.56H0.656V2.344H12.304L11.424 0.0879998H12.192L15.344 2.776V3.112L12.192 5.816H11.424Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightButton;
