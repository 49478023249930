import { useEffect } from "react";
import Web3 from "web3";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import Button from "../button/Button";
import MetamaskIcon from "../../assets/images/metamask-logo.svg";
import AccountIcon from "../../assets/images/account.svg";
import { shortenAddr } from "utils/functions";
import BulletRightButton from "components/svgButton/BulletRightButton";
import { register } from "services/api";
import { useAuth } from "contexts/AuthContext";
import { HTTP_CODE, TEST_NETWORKS, PRESALE_CONTRACTS } from "utils/constants";

const WalletConnectButton = () => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected, status } = useWeb3ModalAccount();
  const { getUserInformation } = useAuth();

  // useEffect(() => {
  //   const web3 = new Web3(window.ethereum);
  //   const checkNetwork = async () => {
  //     const chainId = await web3?.eth?.getChainId();
  //     if (Number(chainId) !== TEST_NETWORKS[0].chainId) {
  //       open({ view: ["Connect", "Networks"] });
  //     }
  //   };
  //   checkNetwork();
  // }, []);
  const handleClick = () => {
    if (isConnected) {
      open({ view: "Account" });
    } else {
      open();
    }
  };

  useEffect(() => {
    // if (
    //   !PRESALE_CONTRACTS.some((contract) => contract.chainId === chainId) ||
    //   !chainId ||
    //   !isConnected
    // ) {
    //   open();
    // }
    if (isConnected) {
      //  NOTE: referralCode is referrerId according to BE
      let referralCode = null;
      referralCode = localStorage.getItem("referralCode");
      register({ walletAddress: address, referralCode })
        .then((res) => {
          if (res?.status === HTTP_CODE.CREATED)
            localStorage.removeItem("referralCode");
        })
        .finally(() => getUserInformation(address));
    } else if (status !== "reconnecting" && !isConnected) {
      open();
    }
  }, [
    isConnected,
    address,
    // chainId
    status,
  ]);

  return (
    <Button
      variant="secondary"
      className="!rounded-[10px] !px-4 !py-[14px] !h-auto border border-[#FFFFFF0F]"
      onClick={handleClick}
    >
      {!isConnected ? (
        "Connect Wallet"
      ) : (
        <div className="flex justify-between items-center w-max">
          <div className="flex justify-center items-center gap-2">
            <img src={AccountIcon} className="w-[24px]" alt="metamask" />
            <p className="text-white font-normal">{shortenAddr(address)}</p>
          </div>
          <div className="text-white">
            <BulletRightButton />
          </div>
        </div>
      )}
    </Button>
  );
};

export default WalletConnectButton;
