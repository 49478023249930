import cn from "classnames";

const images = require.context("../../assets/images/token", true);

const TokenContainer = ({
  token,
  selectedToken,
  setSelectedToken,
  setAmount,
  setReceiveTokens,
}) => {
  return (
    <button
      className={cn(
        "border border-[#FFFFFF0F] rounded-full px-3 py-2 flex justify-center items-center gap-2",
        {
          "bg-white text-black hover:bg-[#FFFFFFcc]":
            selectedToken && selectedToken.tokenSymbol === token.tokenSymbol,
          "bg-[#FFFFFF1A] text-white hover:bg-[#FFFFFF33]": !(
            selectedToken && selectedToken.tokenSymbol === token.tokenSymbol
          ),
        }
      )}
      onClick={() => {
        setSelectedToken(token);
        setAmount("");
        setReceiveTokens("0");
      }}
    >
      {token.tokenSymbol === "USDT" || token.tokenSymbol === "USDC" ? (
        token?.chainName.toLowerCase() === "ethereum" ? (
          <img
            src={images(`./${token.tokenSymbol.toLowerCase()}-eth.svg`)}
            alt="icon"
            width="20px"
          />
        ) : (
          <img
            src={images(`./${token.tokenSymbol.toLowerCase()}.svg`)}
            alt="icon"
            width="20px"
          />
        )
      ) : (
        <img
          src={images(`./${token.tokenSymbol.toLowerCase()}.svg`)}
          alt="icon"
          width="20px"
        />
      )}
      <p className="text-base font-medium leading-none">{token.tokenSymbol}</p>
    </button>
  );
};

export default TokenContainer;
