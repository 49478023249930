// src/components/NavTabs.tsx
import React, { useEffect } from "react";
import NavItem from "./NavItem";
import cn from "classnames";

const NavTabs = ({ tabs, selectedTab, setSelectedTab, isMobile }) => {
  return (
    <div className={cn("flex gap-3 px-2 py-2", { "flex-col": !isMobile })}>
      {tabs.map((tab) => (
        <NavItem
          key={tab.src}
          tabName={tab.tabName}
          svgIcon={tab.svgIcon}
          src={tab.src}
          isSelected={selectedTab === tab.src}
          onClick={() => setSelectedTab(tab.src)}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

export default NavTabs;
