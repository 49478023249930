import { createContext, useContext, useEffect, useState } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import axios from "axios";
import { ethers } from "ethers";
import { apiUrl } from "config/config";
import {
  getLatestPriceOfETH,
  getMinMaxPurchase,
  getStageDetails,
  getUserDetails,
  initializeWeb3,
  isPresaleEnded,
} from "web3-module/presale.web3";
import { getUserInfo } from "services/api";
import { POLLING_INTERVAL, PRESALE_CONTRACTS } from "utils/constants";
import { convertValueFromContract } from "utils/functions";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [referral, setReferral] = useState("");
  const [minPurchase, setMinPurchase] = useState(null);
  const [maxPurchase, setMaxPurchase] = useState(null);
  const [stageInfo, setStageInfo] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [stageId, setStageId] = useState(null);
  const [etherPrice, setEtherPrice] = useState(null);
  const [presaleEnded, setPresaleEnded] = useState(false);
  // const [latestETHPrice, setLatestETHPrice] = useState(0);
  const [fetchLatestNotifTxnData, setFetchLatestNotifTxnData] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);

  const getReferral = async (address) => {
    if (!address) return;
    try {
      const url = `${apiUrl}/getReferralCode/${address}`;
      const res = await axios.get(url);
      if (res && res.data.data && res.data.status) {
        setReferral(res.data.data);
      }
    } catch (err) {
      console.error("Error in getReferral:::", err);
    }
  };
  //  Fetch from SC
  const getUserData = async (address) => {
    try {
      const _userDetails = await getUserDetails(address);
      setUserDetails(_userDetails);
    } catch (err) {
      console.error("Error in getUserData:::", err);
    }
  };
  // from DB
  const getUserInformation = async (address) => {
    try {
      const _userInfo = await getUserInfo(address);
      setUserInfo(_userInfo);
      return _userInfo;
    } catch (err) {
      console.error("Error in getUserInfo:::", err);
    }
  };
  // Fetch from SC
  const fetchPresaleInfo = async () => {
    if (chainId && isInitialized) {
      try {
        let { minPurchase, maxPurchase } = await getMinMaxPurchase();
        minPurchase = Number(ethers.formatUnits(minPurchase, 6));
        maxPurchase = Number(ethers.formatUnits(maxPurchase, 6));
        setMinPurchase(minPurchase);
        setMaxPurchase(maxPurchase);
        const { currentStageId, stageDetails } = await getStageDetails();
        // console.log("stageDetails:::", stageDetails);
        setStageId(Number(currentStageId).toString());
        setStageInfo(stageDetails);
        // console.log("stageDetails:::", stageDetails);
        let latestETHPrice = await getLatestPriceOfETH();
        setEtherPrice(convertValueFromContract(latestETHPrice, 18));
        latestETHPrice = ethers.formatUnits(latestETHPrice, 19);
        // setLatestETHPrice(Number(latestETHPrice));
        const _presaleEnded = await isPresaleEnded();
        setPresaleEnded(_presaleEnded);
      } catch (err) {
        console.error("Error in fetchPresaleInfo:::", err);
      }
    }
  };

  const handleUpdate = async (address) => {
    if (address && isInitialized) {
      getReferral(address).catch((err) => console.error(err));
      getUserData(address).catch((err) => console.error(err)); // userDetails from Smart Contract
      getUserInformation(address).catch((err) => console.error(err)); // userInfo from Database}
    }
  };

  const updateFetchLastestNotifTxnData = async (flag) => {
    setFetchLatestNotifTxnData(flag);
  };

  useEffect(() => {
    if (address && chainId && isInitialized) {
      handleUpdate(address).catch((err) => console.error(err));
      fetchPresaleInfo().catch((err) => console.error(err));
    }
  }, [address, chainId, isInitialized]);

  useEffect(() => {
    if (chainId && walletProvider) {
      initializeWeb3(chainId, walletProvider, setIsInitialized);
    }
  }, [chainId, walletProvider]);

  // TODO: need to check
  useEffect(() => {
    const interval = setInterval(() => {
      handleUpdate(address).catch((err) => console.error(err));
      fetchPresaleInfo().catch((err) => console.error(err));
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [address, chainId]);

  return (
    <AuthContext.Provider
      value={{
        referral,
        setReferral,
        minPurchase,
        setMinPurchase,
        maxPurchase,
        setMaxPurchase,
        stageInfo,
        setStageInfo,
        stageId,
        setStageId,
        userDetails,
        setUserDetails,
        userInfo,
        setUserInfo,
        getUserInformation,
        fetchPresaleInfo,
        etherPrice,
        setEtherPrice,
        presaleEnded,
        setPresaleEnded,
        fetchLatestNotifTxnData,
        updateFetchLastestNotifTxnData,
        isInitialized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
