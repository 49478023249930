/* eslint-disable */
import { formatBalance } from "web3-module/presale.web3";
import { shortenHash } from "utils/functions";
import cn from "classnames";
import ozakLogo from "../../assets/images/ozak-logo.svg";
import confirmedIcon from "../../assets/images/confirmed.svg";
import processingIcon from "../../assets/images/processing.svg";
import CopyButton from "components/copyButton/CopyButton";
import { useMemo } from "react";
import { PRESALE_CONTRACTS } from "utils/constants";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

const tokenImages = require.context("../../assets/images/token", true);

const TransactionCard = ({ txHistory, isFullPage }) => {
  // console.log("txHistory:::", txHistory);
  const { chainId } = useWeb3ModalAccount();

  const explorerUrl = useMemo(() => {
    return (
      PRESALE_CONTRACTS.find((contract) => contract.chainId === chainId)
        ?.explorerUrl || ""
    );
  }, [chainId]);

  return (
    <div
      className={cn(
        "w-full bg-[#FFFFFF0A] border border-[#FFFFFF0F] rounded-[12px] flex justify-between items-center gap-3 my-4",
        {
          "p-[20px]": isFullPage,
          "p-4": !isFullPage,
        }
      )}
    >
      <div
        className={cn("flex items-center", {
          "min-w-[300px] gap-4": isFullPage,
          "min-w-[150px] gap-2": !isFullPage,
        })}
      >
        {/* {txHistory.txType?.includes("Buy") ? ( */}
        <img
          src={tokenImages(`./${txHistory.chainName.toLowerCase()}.svg`)}
          alt="logo"
          width="25px"
          height="25px"
        />
        {/* ) : (
          <img src={ozakLogo} alt="logo" width="25px" height="25px" />
        )} */}
        <div className="flex flex-col gap-1">
          <div className="flex gap-2">
            {!txHistory.txType?.includes("Earnings") && (
              <p
                className={`${isFullPage ? "text-base" : "text-sm"} font-semibold`}
              >
                {formatBalance(txHistory.tokenAmount, 0, true, 3)} OZ
              </p>
            )}
            {/* <p className="text-base font-semibold">
              {txHistory.tokenName}
            </p> */}
            <p
              className={`${isFullPage ? "text-base" : "text-sm"} font-semibold text-[#FFFFFF99]`}
            >
              ${formatBalance(txHistory.value, 0, true, 3)}
            </p>
          </div>
          <div className="flex gap-0">
            <p className="text-xs text-[#FFFFFFE5]">{txHistory.txType}</p>
            {/* <p className="text-xs text-[#FFFFFFE5]">Buy Tokens </p> */}
            {/* {txHistory.txType?.includes("Buy") && ( */}
            {txHistory.txType?.includes("Buy") && (
              <p className="text-xs text-[#FFFFFFE5]">
                - Phase {txHistory.phase}
              </p>
            )}
            {/*  )} */}
            {isFullPage && (
              <p className="text-xs text-[#FFFFFF99] ml-2">{txHistory.date}</p>
            )}
          </div>
          {!isFullPage && (
            <p className="text-xs text-[#FFFFFF99]">{txHistory.date}</p>
          )}
        </div>
      </div>
      {/* Tx Hash */}
      <div>
        <p className="text-sm text-[#FFFFFFB2]">Tx Hash</p>
        <div className="flex items-baseline gap-2">
          <a
            href={`${explorerUrl}/tx/${txHistory.hash}`}
            target="_blank"
            className={`${isFullPage ? "text-base" : "text-sm"} text-[#54A6FF] hover:text-[#54A6FFcc] text-left`}
          >
            {isFullPage
              ? shortenHash(txHistory.hash, 5)
              : shortenHash(txHistory.hash, 3)}
          </a>
          <CopyButton
            copystring={txHistory.hash}
            btnClassName="!p-1"
            imgClassName="w-4 h-4"
          />
        </div>
      </div>
      {/* Status */}
      <div className="flex flex-col gap-1">
        <p className="text-xs text-[#FFFFFFB2]">Status</p>
        <div className="flex gap-2">
          <p
            className={`${isFullPage ? "text-base" : "text-sm"} text-[#FFFFFFE5] font-semibold`}
          >
            {txHistory.status}
          </p>
          <img
            src={
              txHistory.status === "Confirmed" ? confirmedIcon : processingIcon
            }
            alt="status"
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
