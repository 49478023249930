import { ClipboardCopy } from "utils/functions";
import copyImage from "./../../assets/images/copy.svg";
import { toast } from "react-toastify";

const CopyButton = (props) => {
  const { btnClassName, copystring, imgClassName } = props;

  return (
    <button
      className={`p-3 flex justify-center items-center rounded-full bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] border-[#FFFFFF0F] ${btnClassName}`}
      onClick={() => {
        ClipboardCopy(copystring);
        toast.info("Copied!");
      }}
    >
      <img src={copyImage} alt="Copy Icon" className={`${imgClassName}`} />
    </button>
  );
};

export default CopyButton;
