import axios from "axios";
import { API_URL } from "../utils/config/app.config";
import { getApi, postApi, putApi } from "./api.utils";
import { URL_CONFIG } from "../utils/constants";

export const register = async (payload) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.register}`;
    const res = await postApi(url, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const updateUser = async (payload) => {
  // console.log(`updateUser()`, { payload });
  try {
    const res = await postApi(`${API_URL}/${URL_CONFIG.updateUser}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const login = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${URL_CONFIG.login}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const logout = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${URL_CONFIG.logout}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const changePassword = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${URL_CONFIG.changePassword}`,
      payload
    );
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const authUser = async (payload) => {
  try {
    const res = await postApi(`${API_URL}/${URL_CONFIG.authUser}`, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const requestVerificationCode = async (payload) => {
  try {
    const res = await postApi(
      `${API_URL}/${URL_CONFIG.requestVerificationCode}`,
      payload
    );
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const confirmVerificationCode = async (payload) => {
  const url = `${API_URL}/${URL_CONFIG.confirmVerificationCode}`;
  try {
    const res = await postApi(url, payload);
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const getTransactionHistory = async (address) => {
  const url = `${API_URL}/${URL_CONFIG.getTransactionHistory}/${address}`;
  try {
    if (!address) return [];
    const res = await getApi(url);
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const createTransactionActivity = async (payload) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.createTransactionActivity}`;
    const res = await postApi(url, payload);
    if (res.status === "success") {
      return true;
    }
    console.log("res:::", res);
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserInfo = async (address) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.getUserInfo}/${address}`;
    const res = await getApi(url);
    if (res.status === "success") {
      return res.data;
    }
    return [];
  } catch (err) {
    console.error("Error in getUserInfo:::", err);
    return err.response;
  }
};
// Get referrer address using referral code
export const getReferrerAddress = async (referralCode) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.getReferrerAddress}/?ref=${referralCode}`;
    const res = await getApi(url);
    if (res.status === "success") {
      return res.data;
    }
    return null;
  } catch (err) {
    console.error("Error in getReferrerAddress:::", err);
    return err.response;
  }
};

export const updateReferral = async (payload) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.updateReferral}`;
    const res = await postApi(url, payload);
    // console.log(res.status);
    if (res.status === "success" || res.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    console.error("Error in updateReferral:::", err);
    return err.response;
  }
};

export const getUsersRanking = async () => {
  try {
    const url = `${API_URL}/${URL_CONFIG.getUsersRanking}`;
    const res = await getApi(url);
    if (res.status === "success") {
      return res.data;
    } else return [];
  } catch (err) {
    console.error("Error in getUsersRanking:::", err);
    return err.response;
  }
};

export const createNotification = async (payload) => {
  const url = `${API_URL}/${URL_CONFIG.createNotification}`;
  try {
    const res = await postApi(url, payload);
    // console.log("res in createNotification:::", res);
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchNotification = async (address) => {
  if (!address) return [];
  try {
    const url = `${API_URL}/${URL_CONFIG.fetchNotification}/${address}`;
    const res = await getApi(url);
    return res.data;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};

export const markAllNotificationsAsRead = async (address) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.markAllNotificationsAsRead}/${address}`;
    const res = await putApi(url);
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const url = `${API_URL}/${URL_CONFIG.markNotificationAsRead}/${notificationId}`;
    const res = await putApi(url);
    if (res.status === "success") {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
