import { Modal } from "antd";
import Lottie from "react-lottie-player";
import progressImage from "../../assets/images/transaction-progress.svg";
import InProgressJson from "../../assets/json/InProgress.json";
import "./styles.css";

const ProgressModal = ({
  modalOpen,
  setModalOpen,
  onOk,
  onCancel,
  title = "Buy Token",
  ...rest
}) => {
  return (
    <Modal
      open={modalOpen}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
      rootClassName="buy-token-modal"
      centered
      destroyOnClose={false}
      footer={false}
      maskClosable={false}
      {...rest}
    >
      <div className="flex flex-col items-center gap-5">
        {/* <img src={progressImage} alt="icon" /> */}
        <Lottie
          loop
          animationData={InProgressJson}
          play
          style={{ width: 150, height: 150 }}
        />
        <p className="text-[24px] text-[#FFFFFF] font-medium leading-none">
          Transaction in Progress
        </p>
        <p className="text-xs text-[#FFFFFF80] text-center">
          Note: After making the payment, please return to the website and wait
          for the Purchase Successful pop-up.
        </p>
      </div>
    </Modal>
  );
};

export default ProgressModal;
