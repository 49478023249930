import { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import MenuLayout from "./components/layout/MenuLayout";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { web3ModalProjectId } from "./config/config";
import { ToastContainer } from "react-toastify";
import HomePage from "pages/HomePage";
import DashboardPage from "pages/DashboardPage";
import BuyPage from "pages/BuyPage";
import ROIPage from "pages/ROIPage";
import LeaderboardPage from "pages/LeaderboardPage";
import TransactionPage from "pages/TransactionPage";
import ProfilePage from "pages/ProfilePage";
import { NETWORKS, TEST_NETWORKS } from "utils/constants";
import { setWeb3 } from "web3-module/presale.web3";
import "react-toastify/dist/ReactToastify.css";
import { APP_URL, ACTIVE_CHAIN_ID } from "utils/config/app.config";

// 1. Your WalletConnect Cloud project ID
const projectId = web3ModalProjectId;

// 3. Create a metadata object
const metadata = {
  name: "OzakAI",
  description: "OzakAI Presale",
  url: APP_URL, // origin must match your domain & subdomain
  icons: [
    "https://miro.medium.com/v2/resize:fill:72:72/1*KWHaiYjHbJi1Y4Wfs0I4jg.png",
  ],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
  auth: {
    email: false,
    socials: [],
  },
});

// 5. Create a Web3Modal instance
try {
  const network = NETWORKS.find((x) => x.chainId === ACTIVE_CHAIN_ID);
  createWeb3Modal({
    ethersConfig,
    cacheProvider: true,
    chains: [
      {
        chainId: network.chainId,
        name: network.chainName,
        currency: network.nativeCurrency.symbol,
        explorerUrl: network.blockExplorerUrls[0],
        // rpcUrl: network.rpcUrls[0],
      },
    ],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });
} catch (err) {
  console.error(err);
}
// const AuthenticatedRoutes = () => {
//   const {
//     isAuthenticated,
//     isLoading,
//     loginWithRedirect,
//     user,
//     loginWithPopup,
//   } = useAuth0();

//   useEffect(() => {
//     console.log({ isAuthenticated, isLoading });
//     if (!isLoading && !isAuthenticated) {
//       loginWithRedirect();
//     }
//   }, [isAuthenticated, isLoading, user, loginWithRedirect]);

//   if (isLoading) {
//     return <div>Authenticating...</div>;
//   } else {
//     if (isAuthenticated) {
//       return (
//         <Routes>
//           <Route path="/" element={<MenuLayout />}>
//             <Route path="/" element={<DashboardPage />} />
//             <Route path="/dashboard" element={<DashboardPage />} />
//           </Route>
//         </Routes>
//       );
//     } else {
//       return <button onClick={() => loginWithRedirect()}>Log in</button>;
//     }
//   }
// };

const App = () => {
  // useEffect(() => {
  //   setWeb3();
  // }, []);

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="*" element={<AuthenticatedRoutes />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/" element={<MenuLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/buy" element={<BuyPage />} />
            <Route path="/roi-calculator" element={<ROIPage />} />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/transactions" element={<TransactionPage />} />
            <Route path="/profile" element={<ProfilePage />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
