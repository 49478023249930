import { Modal } from "antd";
import successImage from "../../assets/images/transaction-success.svg";
import "./styles.css";

const ClaimSuccessModal = ({
  modalOpen,
  setModalOpen,
  onOk,
  onCancel,
  ...rest
}) => {
  return (
    <Modal
      open={modalOpen}
      onOk={onOk}
      onCancel={onCancel}
      title="Claim Token"
      rootClassName="buy-token-modal"
      centered
      destroyOnClose={false}
      footer={false}
      maskClosable={false}
      {...rest}
    >
      <div className="flex flex-col items-center gap-5">
        <img src={successImage} alt="icon" />
        <p className="text-[24px] text-[#FFFFFF] font-medium leading-none">
          Tokens Claimed
        </p>
        <p className="text-xs text-[#FFFFFF80] leading-none text-center">
          Your transaction was successful.
        </p>
      </div>
    </Modal>
  );
};

export default ClaimSuccessModal;
