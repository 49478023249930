import { useState, useEffect } from "react";
import { Dropdown } from "antd";
import cn from "classnames";
import notificationImage from "../../assets/images/bell.svg";
import CloseButton from "components/svgButton/CloseButton";
import * as apiService from "services/api";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { POLLING_INTERVAL } from "utils/constants";
import "./styles.css";
import { useAuth } from "contexts/AuthContext";

const NotificationDropdown = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const { address } = useWeb3ModalAccount();
  const { fetchLatestNotifTxnData, updateFetchLastestNotifTxnData } = useAuth();

  const updateData = async () => {
    if (!address) return;
    const _notifications = await apiService.fetchNotification(address); // TODO: socket instead of setInterval
    if (!_notifications) return;
    setNotifications(_notifications);
    setNewNotifications(_notifications.filter((obj) => obj.isRead === false));
    updateFetchLastestNotifTxnData(false);
  };

  const markAllAsRead = async () => {
    await apiService.markAllNotificationsAsRead(address);
  };

  const markAsRead = async (id, index) => {
    if (index >= notifications.length || notifications[index].isRead) return;
    const _newNotifications = [...newNotifications];
    _newNotifications.splice(index, 1);
    setNewNotifications(_newNotifications);
    await apiService.markNotificationAsRead(id);
  };

  useEffect(() => {
    if (fetchLatestNotifTxnData || address)
      updateData().catch((err) => console.error(err));
  }, [address, fetchLatestNotifTxnData]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateData().catch((err) => console.error(err));
    }, POLLING_INTERVAL * 2);
    return () => clearInterval(interval);
  }, [address]);

  const handleMenuClick = ({ key }) => {};

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "header",
            label: (
              <div className="">
                <div className="flex justify-between items-center p-6 md:min-w-[420px]">
                  <p className="text-xl font-medium text-white leading-none">
                    Notifications
                  </p>
                  <button
                    className="flex items-center gap-2 text-[#ffffff] hover:text-[#ffffffcc]"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseButton />
                  </button>
                </div>
                <hr className="notification-hr" />
              </div>
            ),
          },
          ...notifications.map((item, index) => ({
            key: `${index + 1}`,
            label: (
              <div
                onClick={() => markAsRead(item.id, index)}
                className={`${item.isRead ? "text-gray-400" : "text-white"} font-bold hover:bg-[#FFFFFF1A] p-6`}
              >
                <p className="text-lg font-medium mb-4 leading-none">
                  {item.title}
                </p>
                <div>
                  <p className="noti-content-text leading-none mb-2">
                    {item.message}
                  </p>
                  <p className="noti-content-text leading-none">
                    {new Date(item.createdAt).toLocaleString()}
                  </p>
                </div>
              </div>
            ),
          })),
          notifications.length === 0 && {
            key: "no",
            label: (
              <p className="text-white text-center mt-[50px]">
                No Notifications!
              </p>
            ),
          },
        ],
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      open={open}
      placement="bottomRight"
      trigger="click"
      rootClassName="notification-dropdown"
    >
      <button
        onClick={(e) => e.preventDefault()}
        className={cn(
          "p-3 flex justify-center items-center rounded-[10px] bg-[#FFFFFF1A] border border-[#FFFFFF0F] hover:bg-[#FFFFFF4D] relative",
          {
            "bg-[#FFFFFF4D]": open,
            "border-[#FFFFFF0F]": !open,
          }
        )}
      >
        <img src={notificationImage} alt="link" width={28} height={28} />
        {newNotifications.length > 0 && (
          <div className="absolute text-xs font-medium top-[-6px] right-[-6px] w-6 h-6 bg-[#6034DD] rounded-full flex justify-center items-center">
            {newNotifications.length}
          </div>
        )}
      </button>
    </Dropdown>
  );
};

export default NotificationDropdown;
