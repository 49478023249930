import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { ClipboardCopy } from "utils/functions";
import { useAuth } from "contexts/AuthContext";
import copyImage from "../../assets/images/copy.svg";
import linkImage from "../../assets/images/link.svg";
import ReferralModal from "components/modal/ReferralModal";
import { BASE_COLOR, HIGHLIGHT_COLOR } from "utils/constants";
import { formatBalance } from "web3-module/presale.web3";
import "react-loading-skeleton/dist/skeleton.css";
import CopyButton from "components/copyButton/CopyButton";

const ReferralCard = ({ referralEarning, followers }) => {
  const navigate = useNavigate();
  const { referral } = useAuth();
  const [referralModalOpen, setReferralModalOpen] = useState(false);
  const referralLink = `${window.location.origin}/buy?ref=${referral}`;

  const handleClickCard = () => {
    navigate("/profile");
  };

  const handleClickLink = () => {
    setReferralModalOpen(true);
  };

  return (
    <div
      className="bg-[#FFFFFF0A] rounded-[24px] p-6 w-full border border-[#FFFFFF0F] min-h-[350px]"
      // onClick={handleClickCard}
    >
      <div className="flex justify-between">
        <p className="text-[24px] font-medium mb-6">Referral</p>
      </div>
      <div className="flex gap-3">
        <div className="p-6 bg-[#FFFFFF0D] rounded-[12px] w-1/2">
          <p className="font-medium text-[24px] leading-none mb-3">
            {followers >= 0 ? (
              followers
            ) : (
              <Skeleton
                width={60}
                height={24}
                baseColor={BASE_COLOR}
                highlightColor={HIGHLIGHT_COLOR}
              />
            )}
          </p>
          <p className="font-normal text-base text-[#FFFFFF99] leading-none">
            Your Referral
          </p>
        </div>
        <div className="p-6 bg-[#FFFFFF0D] rounded-[12px] w-1/2">
          {referralEarning >= 0 ? (
            <p className="font-medium text-[24px] leading-none mb-3">
              {`${formatBalance(referralEarning, 0, true, 3)} OZ`}
            </p>
          ) : (
            <Skeleton
              width={80}
              height={24}
              baseColor={BASE_COLOR}
              highlightColor={HIGHLIGHT_COLOR}
              className="mb-3"
            />
          )}
          <p className="font-normal text-base text-[#FFFFFF99] leading-none">
            Referral Earning
          </p>
        </div>
      </div>
      {/* Referral Link */}
      <div className="p-6 bg-[#FFFFFF0D] rounded-[16px] flex justify-between items-center mt-6">
        <div className="flex flex-col gap-2">
          <p className="text-base text-[#FFFFFF99]">Referral Link</p>
          <a
            className="text-[18px] font-medium text-[#4678FF] cursor-pointer inline-block max-w-[399px] whitespace-nowrap overflow-hidden overflow-ellipsis"
            href={referralLink}
            target="_blank"
          >
            {referralLink}
          </a>
        </div>
        <div className="flex gap-3 items-center">
          <CopyButton copystring={referralLink} />
          <button
            className="p-3 flex justify-center items-center rounded-full bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] border-[#FFFFFF0F]"
            onClick={handleClickLink}
          >
            <img src={linkImage} alt="link" />
          </button>
        </div>
      </div>
      <ReferralModal
        modalOpen={referralModalOpen}
        setModalOpen={setReferralModalOpen}
        onOk={() => setReferralModalOpen(false)}
        onCancel={() => setReferralModalOpen(false)}
        referralLink={referralLink}
      />
    </div>
  );
};

export default ReferralCard;
