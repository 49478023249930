import { ethers } from "ethers";
import { toast } from "react-toastify";
import {
  NETWORKS,
  PRESALE_CONTRACTS,
  TEST_NETWORKS,
  ZERO_ADDRESS,
} from "utils/constants";
import Web3 from "web3";
import { BrowserProvider, Contract, Eip1193Provider } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";

let web3;
let account;
let presaleContract;
let signer;
let provider;

const DEFAULT_GAS_LIMIT = 200000;

const TOKEN_PRESALE_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "AccidentalTokenWithdrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "ClaimReleased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "minPurchase",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxPurchase",
        type: "uint256",
      },
    ],
    name: "MinMaxPurchaseUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    name: "PresaleFinalized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "PresalePaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "PresaleResumed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonusAmount",
        type: "uint256",
      },
    ],
    name: "ReferrerBonusClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "ReferrerBonusReleased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newBonus",
        type: "uint256",
      },
    ],
    name: "ReferrerBonusUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "stageId",
        type: "uint256",
      },
    ],
    name: "StageFinalized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newAllocation",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newRate",
        type: "uint256",
      },
    ],
    name: "StageParameterUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "stageId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokensAllocated",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
    ],
    name: "StageStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newTokenAddress",
        type: "address",
      },
    ],
    name: "TokenAddressupdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "TokenDelisted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "TokenWhitelisted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "vestedTokens",
        type: "uint256",
      },
    ],
    name: "TokensClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "purchaser",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "referrer",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "referralBonusAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "batchId",
        type: "uint256",
      },
    ],
    name: "TokensPurchased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newTreasury",
        type: "address",
      },
    ],
    name: "TreasuryUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newVestingDuration",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newCliffDuration",
        type: "uint256",
      },
    ],
    name: "VestingParametersUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "vestingPercentage",
        type: "uint256",
      },
    ],
    name: "VestingPercentageUpdated",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "usdAmount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "referrer",
        type: "address",
      },
    ],
    name: "buyTokens",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "referrer",
        type: "address",
      },
    ],
    name: "buyTokensETH",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimReferrerBonus",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimReleased",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "claimableTokens",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cliffDuration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "contributions",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "currentStageId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
    ],
    name: "delistPaymentTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "endPresaleStage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ethDataFeed",
    outputs: [
      {
        internalType: "contract AggregatorV3Interface",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "finalisePresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getLatestPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getWhitelistedTokens",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenAllocation",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
    ],
    name: "initNextPresaleStage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_minPurchase",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_maxPurchase",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_vestingDuration",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cliffDuration",
        type: "uint256",
      },
      {
        internalType: "address payable",
        name: "_treasury",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_referrerBonusPercentage",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_ethPriceFeed",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_vestingPercentage",
        type: "uint256",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "maxPurchase",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minPurchase",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pausePresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "presaleEnded",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "referrerBonusPercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "referrerBonusReleased",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "releaseClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "releaseReferrerBonus",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "resumePresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "stages",
    outputs: [
      {
        internalType: "uint256",
        name: "tokensAllocated",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensSold",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "startTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "endTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amountRaised",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isActive",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "referrerBonusAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract IERC20Upgradeable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalReferrerBonusAllocated",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "treasury",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newCliffDuration",
        type: "uint256",
      },
    ],
    name: "updateCliffDuration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newMinPurchase",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newMaxPurchase",
        type: "uint256",
      },
    ],
    name: "updateMinMaxPurchase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newTokenAllocated",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newRate",
        type: "uint256",
      },
    ],
    name: "updatePresaleStageParameters",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newBonusPercentage",
        type: "uint256",
      },
    ],
    name: "updateReferrerBonusPercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newTokenAddress",
        type: "address",
      },
    ],
    name: "updateTokenAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "newTreasury",
        type: "address",
      },
    ],
    name: "updateTreasury",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newVestingDuration",
        type: "uint256",
      },
    ],
    name: "updateVestingDuration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newVestingPercentage",
        type: "uint256",
      },
    ],
    name: "updateVestingPercentage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "users",
    outputs: [
      {
        internalType: "uint256",
        name: "usdInvested",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensAllocated",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "referrerEarnings",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "referrerEarningsClaimed",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensClaimed",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "noOfReferrals",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingDuration",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingPercentage",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vestingStartTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
    ],
    name: "whitelistPaymentTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "whitelistedTokens",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
    ],
    name: "withdrawAccidentalFunds",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

const TOKEN_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "subtractedValue",
        type: "uint256",
      },
    ],
    name: "decreaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "addedValue",
        type: "uint256",
      },
    ],
    name: "increaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol",
        type: "string",
      },
      {
        internalType: "uint8",
        name: "decimal",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "initialSupply",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "treasury",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

// // Check if MetaMask is installed

// export const connectWallet = async () => {
//   if (typeof window.ethereum !== "undefined") {
//     // MetaMask is installed
//     try {
//       web3 = new Web3(window.ethereum);
//       await window.ethereum.enable();

//       const chainId = await getChainId();
//       if (!PRESALE_CONTRACTS.find((contract) => contract.chainId === chainId)) {
//         return;
//       } else {
//         // Request account access if needed
//         window.ethereum
//           .request({ method: "eth_requestAccounts" })
//           .then((accounts) => {
//             // Get the first account address
//             account = accounts[0];
//           })
//           .catch((error) => {
//             console.error("Error connecting to MetaMask:", error);
//           });
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   } else {
//     console.error("MetaMask is not installed");
//   }
// };

// connectWallet();

// //  TODO: integrate with web3modal hook
// export const setAccount = (walletAddress) => {
//   account = walletAddress;
// };

// export const setWeb3 = () => {
//   web3 = new Web3(window.ethereum);
// };

export const initializeWeb3 = async (
  chainId,
  walletProvider,
  setIsInitialized
) => {
  provider = new BrowserProvider(walletProvider);
  signer = await provider.getSigner();
  web3 = new Web3(walletProvider);
  const address = await signer.getAddress();
  //   const short = shortenAddr(address);
  account = address;

  // const chainId = await getChainId();
  const contract = PRESALE_CONTRACTS.find((obj) => obj.chainId === chainId);
  presaleContract = new Contract(contract.address, TOKEN_PRESALE_ABI, signer);
  setIsInitialized(true);
};

export const getPresaleContract = async () => {
  try {
    // const network = await getNetwork();
    const chainId = await getChainId();
    // console.log("Chain ID in getPresaleContract:::", chainId);
    const contract = PRESALE_CONTRACTS.find((obj) => obj.chainId === chainId);
    // console.log("contract found in getPresaleContract:::", contract);
    let presaleContract;
    if (contract) {
      presaleContract = new web3.eth.Contract(
        TOKEN_PRESALE_ABI,
        contract.address
      );
    }
    // console.log("presaleContract in getPresaleContract:::", presaleContract);
    if (!contract || !presaleContract) {
      // throw new Error("Invalid contract");
      return;
    }
    return presaleContract;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/************************************ WRITE FUNCTIONS **************************************************/

/**
 * @dev Allows users to purchase tokens during the presale by sending whitelisted ERC20 tokens.
 * @param tokenAddress The address of the ERC20 token used for payment.
 * @param usdAmount The amount of ERC20 tokens being spent.
 * @param referrer The address of the referrer.
 * @return  Transaction Hash of the signed transaction.
 */
export const buyTokens = async (tokenAddress, usdAmount, referrer) => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");
    usdAmount = usdAmount.toString();

    // Determine the referrer to use, which could be either an actual referrer or null.
    const _referrer = referrer || ZERO_ADDRESS;

    //Find gas price
    let gasPrice = await web3.eth.getGasPrice();

    // Estimate gas limit
    const presaleContractInWeb3 = await getPresaleContract();
    let gasLimit;
    try {
      gasLimit = await presaleContractInWeb3.methods
        .buyTokens(tokenAddress, usdAmount, _referrer)
        .estimateGas({
          from: account,
          value: 0,
        });
      // console.log({ gasLimit });
    } catch (err) {
      console.log("Estimate gas error: ", err);
      gasLimit = BigInt(DEFAULT_GAS_LIMIT); // This is dummy value in the case of failure to estimate gas
      // return "Estimate gas error";
    }

    // Call a function of the contract
    return await presaleContractInWeb3.methods
      .buyTokens(tokenAddress, usdAmount, _referrer)
      .send({
        from: account,
        gas: gasLimit + gasLimit / BigInt(2),
        gasPrice,
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      })
      .once("transactionHash", (hash) => {
        console.log("Transaction Hash:", hash);
        return hash;
      });

    // // Call a function of the contract with computed gas:
    // const tx = await presaleContract.buyTokens(
    //   tokenAddress,
    //   usdAmount,
    //   _referrer,
    //   { gasPrice }
    // );
    // const receipt = await tx.wait();
    // console.log("Transaction successful", receipt);
    // return receipt;
  } catch (err) {
    // console.log("Error", err);
    // throw err;
    throw err;
  }
};

// Function to Buy Tokens with ETH

/**
 * @dev Allows users to purchase tokens during the presale by sending ETH.
 * @param ethAmount The amount of ETH being sent.
 * @param referrer The address of the referrer.
 */
export const buyTokensETH = async (ethAmount, referrer) => {
  // console.log(`buyTokensETH(${ethAmount}, ${referrer})`);
  try {
    // web3.eth.net.getId().then((netID) => {
    //   console.log(`buyTokensETH:::`, { netID });
    // });
    // // Contract instance
    // const presaleContract1 = await getPresaleContract();
    // if (!presaleContract1) throw new Error("Invalid contract");

    // Find gas price
    let gasPrice = await web3.eth.getGasPrice();
    // console.log({ gasPrice });
    // // Convert ethAmount to Wei
    const ethAmountInWei = web3.utils.toWei(ethAmount, "ether");
    // console.log({ ethAmountInWei });
    const _referrer = referrer || ZERO_ADDRESS;

    // Estimate gas limit
    const presaleContractInWeb3 = await getPresaleContract();
    let gasLimit;
    try {
      gasLimit = await presaleContractInWeb3.methods
        .buyTokensETH(_referrer)
        .estimateGas({
          from: account,
          value: ethAmountInWei,
        });
      // console.log({ gasLimit });
    } catch (err) {
      console.log("Estimate gas error: ", err);
      gasLimit = BigInt(DEFAULT_GAS_LIMIT); // This is dummy value in the case of failure to estimate gas
      // return "Estimate gas error";
    }

    // Call a function of the contract
    return await presaleContractInWeb3.methods
      .buyTokensETH(_referrer)
      .send({
        from: account,
        value: ethAmountInWei,
        gas: gasLimit + gasLimit / BigInt(2),
        gasPrice,
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      })
      .once("transactionHash", (hash) => {
        console.log("Transaction Hash:", hash);
        return hash;
      });

    // // Call a function of the contract
    // const tx = await presaleContract.buyTokensETH(_referrer, {
    //   value: ethAmountInWei,
    //   gasPrice,
    //   gasLimit,
    // });
    // const receipt = await tx.wait();
    // console.log("Transaction successful", receipt);
    // return receipt;
  } catch (err) {
    console.log("Error", err);
    throw err;
  }
};

// Check if claim duration has passed or not
export const isClaimDurationPassed = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let vestingStartTime = await presaleContract.vestingStartTime();
    vestingStartTime = parseInt(vestingStartTime, 10);

    let cliffDuration = await presaleContract.cliffDuration();
    cliffDuration = parseInt(cliffDuration, 10);

    let cliffEndTime = vestingStartTime + cliffDuration;
    let currentBlock = await web3.eth.getBlock("latest");
    let currentTimestamp = currentBlock.timestamp;

    if (currentTimestamp >= cliffEndTime && vestingStartTime !== 0) {
      return true;
    }
    return false;
  } catch (err) {
    console.error("Error in isClaimDurationPassed:::", err);
    throw err;
  }
};

// Function to Claim Tokens
/**
 * @dev Allows users to claim their purchased tokens after the presale.
 */
export const claimTokens = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    const claimableTokens = await presaleContract.claimableTokens(account);

    if (claimableTokens <= 0) {
      toast("No tokens to claim at this time");
      return;
    }

    const presaleEnded = await presaleContract.presaleEnded();
    if (!presaleEnded) {
      toast("Presale is still active");
      return;
    }

    const claimReleased = await presaleContract.claimReleased();
    if (!claimReleased) {
      toast("Claim not released yet");
      return;
    }

    // Find gas price
    // let gasPrice = await web3.eth.getGasPrice();

    // // Find gas limit
    // let limit = await presaleContract.methods.claimTokens().estimateGas({
    //   from: account,
    // });

    // // Call a function of the contract
    // return await presaleContract.methods
    //   .claimTokens()
    //   .send({
    //     from: account,
    //     gasPrice: gasPrice,
    //     gas: limit,
    //   })
    //   .on("transactionHash", function (hash) {
    //     // console.log("transactionHash", hash);
    //   });

    // Call a function of the contract
    return presaleContract.claimTokens().then((tx) => tx.wait());
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Function to Claim Referrer Bonus
/**
 * @dev Allows users to claim their referrer bonus after the presale.
 */
export const claimReferrerBonus = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // // Find gas price
    // let gasPrice = await web3.eth.getGasPrice();

    // // Find gas limit
    // let limit = await presaleContract.methods.claimReferrerBonus().estimateGas({
    //   from: account,
    // });

    // // Call a function of the contract
    // return await presaleContract.methods
    //   .claimReferrerBonus()
    //   .send({
    //     from: account,
    //     gasPrice: gasPrice,
    //     gas: limit,
    //   })
    //   .on("transactionHash", function (hash) {
    //     // console.log("transactionHash", hash);
    //   });

    // Call a function of the contract
    return presaleContract.claimReferrerBonus().then((tx) => tx.wait());
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

//End Presale Stage
/**
 * @dev Ends the current presale stage.
 */
export const endPresaleStage = async () => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods.endPresaleStage().estimateGas({
      from: account,
    });

    return await presaleContract.methods
      .endPresaleStage()
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Finalise Presale
/**
 * @dev Finalises the presale.
 */
export const finalisePresale = async () => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods.finalisePresale().estimateGas({
      from: account,
    });

    return await presaleContract.methods
      .finalisePresale()
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Release Claim
/**
 * @dev Releases the claim tokens.
 */
export const releaseClaim = async () => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods.releaseClaim().estimateGas({
      from: account,
    });

    return await presaleContract.methods
      .releaseClaim()
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Release Referrer Bonus
/**
 * @dev Release referrer bonus
 */
export const releaseReferrerBonus = async () => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .releaseReferrerBonus()
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .releaseReferrerBonus()
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Whitelist Payment Tokens

/**
 * @dev Whitelists a set of ERC20 payment tokens.
 * @param tokenAddresses The array of token addresses to whitelist.
 */
export const whitelistPaymentTokens = async (tokenAddresses) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .whitelistPaymentTokens(tokenAddresses)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .whitelistPaymentTokens(tokenAddresses)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Delist Payment Tokens

/**
 * @dev Delists a set of ERC20 payment tokens.
 * @param tokenAddresses The array of token addresses to delist.
 */
export const delistPaymentTokens = async (tokenAddresses) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .delistPaymentTokens(tokenAddresses)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .delistPaymentTokens(tokenAddresses)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Update Min/Max Purchase
/**
 * @dev Updates the minimum and maximum purchase amounts.
 * @param minPurchase The minimum purchase amount.
 * @param maxPurchase The maximum purchase amount.
 */
export const updateMinMaxPurchase = async (minPurchase, maxPurchase) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .updateMinMaxPurchase(minPurchase, maxPurchase)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .updateMinMaxPurchase(minPurchase, maxPurchase)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Update Presale Stage Parameters
/**
 * @dev Updates the parameters for the current presale stage.
 * @param newTokenAllocated The new token allocated.
 * @param newRate The new rate for the stage.
 */
export const updatePresaleStageParameters = async (
  newTokenAllocated,
  newRate
) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .updatePresaleStageParameters(newTokenAllocated, newRate)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .updatePresaleStageParameters(newTokenAllocated, newRate)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Update Cliff Duration

/**
 * @dev Updates the cliff duration for the presale.
 * @param newCliffDuration The new cliff duration.
 */
export const updateCliffDuration = async (newCliffDuration) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .updateCliffDuration(newCliffDuration)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .updateCliffDuration(newCliffDuration)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Update Vesting Duration
/**
 * @dev Updates the vesting duration for the presale.
 * @param newVestingDuration The new vesting duration.
 */
export const updateVestingDuration = async (newVestingDuration) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .updateVestingDuration(newVestingDuration)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .updateVestingDuration(newVestingDuration)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Initialize Next Presale Stage
/**
 * @dev Initializes the next presale stage.
 * @param tokenAllocation The total number of tokens allocated for this stage.
 * @param rate The rate at which tokens are sold per USD.
 */
export const initNextPresaleStage = async (tokenAllocation, rate) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .initNextPresaleStage(tokenAllocation, rate)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .initNextPresaleStage(tokenAllocation, rate)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Update Referrer Bonus Percentage
/**
 * @dev Updates the referrer bonus percentage.
 * @param newPercentage The new referrer bonus percentage.
 */
export const updateReferrerBonusPercentage = async (newPercentage) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .updateReferrerBonusPercentage(newPercentage)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .updateReferrerBonusPercentage(newPercentage)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Update Treasury
/**
 * @dev Updates the treasury address.
 * @param newTreasury The new treasury address.
 */
export const updateTreasury = async (newTreasury) => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods
      .updateTreasury(newTreasury)
      .estimateGas({
        from: account,
      });

    return await presaleContract.methods
      .updateTreasury(newTreasury)
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Pause Presale

/**
 * @dev Pauses the presale.
 */
export const pausePresale = async () => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods.pausePresale().estimateGas({
      from: account,
    });

    return await presaleContract.methods
      .pausePresale()
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Resume Presale

/**
 * @dev Resumes the presale.
 */
export const resumePresale = async () => {
  try {
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();
    let limit = await presaleContract.methods.resumePresale().estimateGas({
      from: account,
    });

    return await presaleContract.methods
      .resumePresale()
      .send({
        from: account,
        gasPrice: gasPrice,
        gas: limit,
      })
      .on("transactionHash", function (hash) {
        // console.log("transactionHash", hash);
      });
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const approve = async (
  tokenContractAddress,
  spenderAddress,
  amount = "10000000000000000000"
) => {
  try {
    const tokenContractInstance = new web3.eth.Contract(
      TOKEN_ABI,
      tokenContractAddress
    );
    // const tokenContractInstance = new Contract(
    //   tokenContractAddress,
    //   TOKEN_ABI,
    //   signer
    // );
    if (!tokenContractInstance) throw new Error("Invalid contract");

    let gasPrice = await web3.eth.getGasPrice();

    // Estimate gas limit
    let gasLimit;
    try {
      gasLimit = await tokenContractInstance.methods
        .approve(spenderAddress, amount)
        .estimateGas({
          from: account,
        });
    } catch (err) {
      console.log("Estimate gas error: ", err);
      gasLimit = BigInt(DEFAULT_GAS_LIMIT); // This is dummy value in the case of failure to estimate gas
      // return "Estimate gas error";
    }

    // Call a function of the contract
    return await tokenContractInstance.methods
      .approve(spenderAddress, amount)
      .send({
        from: account,
        gas: gasLimit + gasLimit / BigInt(2),
        gasPrice,
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      })
      .once("transactionHash", (hash) => {
        console.log("Transaction Hash:", hash);
        return hash;
      });

    // let limit = await tokenContractInstance.methods
    //   .approve(spenderAddress, amount)
    //   .estimateGas({
    //     from: account,
    //   });

    // return await tokenContractInstance.methods
    //   .approve(spenderAddress, amount)
    //   .send({
    //     from: account,
    //     gasPrice: gasPrice,
    //     gas: limit,
    //   })
    //   .on("transactionHash", function (hash) {
    //     // console.log("transactionHash", hash);
    //   });

    // return tokenContractInstance
    //   .approve(spenderAddress, amount, { gasPrice })
    //   .then((tx) => tx.wait());
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/************************************ READ FUNCTIONS **************************************************/

// Function to Check Claimable Tokens

/**
 * @dev Checks the number of tokens that can be claimed by the user.
 * @param userAddress Address of the user
 * @return {string} The number of claimable tokens.
 */
export const claimableTokens = async (userAddress = account) => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let data = await presaleContract.claimableTokens(userAddress);
    return data;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

// Function to Check Token Whitelist Status
/**
 * @dev Checks if a token is whitelisted.
 * @param token The address of the token to check.
 * @return {boolean} True if the token is whitelisted, otherwise false.
 */
export const isTokenWhitelisted = async (token) => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract)
      // throw new Error("Invalid contract");
      return;
    // Call a function of the contract
    let data = await presaleContract.whitelistedTokens(token);
    return data;
  } catch (err) {
    // console.log("Error in isTokenWhitelisted:::", err);
    throw err;
  }
};

/**
 *@dev Check whether spender address approved
 *@param tokenAddress address of the token
 *@param  userAddress address of the user
 *@param spenderAddress Address of spender to which token is approved
 *@return last approved transaction amount
 */
export const getApprovedAmount = async (
  tokenContractAddress,
  userAddress,
  spenderAddress
) => {
  // console.log(`getApprovedAmount(${tokenContractAddress}, ${userAddress}, ${spenderAddress})`);
  try {
    // Contract instance
    // const tokenContractInstance = new web3.eth.Contract(
    //   TOKEN_ABI,
    //   tokenContractAddress
    // );
    const tokenContractInstance = new Contract(
      tokenContractAddress,
      TOKEN_ABI,
      signer
    );

    if (!tokenContractInstance) throw new Error("Invalid contract");

    // Call a function of the contract
    let data = await tokenContractInstance.allowance(
      userAddress,
      spenderAddress
    );
    return data;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/**
 *@dev Function to get min and max purchase in USDT (has 6 decimal)
 *@return min and max purchase in USDT (has 6 decimal)
 */
export const getMinMaxPurchase = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let minPurchase = await presaleContract.minPurchase();
    let maxPurchase = await presaleContract.maxPurchase();
    return { minPurchase, maxPurchase };
  } catch (err) {
    console.error("Error in getMinMaxPurchase:::", err);
    throw err;
  }
};

/**
 *@dev Function to get all the presale current stage details.
 *@return Returns rate in USD. stages is a mapping which returns all the details related to the current stage like tokensAllocated, tokensSold, rate etc.
 */
export const getStageDetails = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract)
      // throw new Error("Invalid contract");
      return;
    // Call a function of the contract
    let currentStageId = await presaleContract.currentStageId();
    let stageDetails = await presaleContract.stages(currentStageId);
    // let rate = stageDetails[2];
    return { currentStageId, stageDetails };
  } catch (err) {
    console.error("Error in presaleContract:::", err);
    throw err;
  }
};

/**
 *@dev Function to get all the details of a user.
 *@param userAddress address of the user
 *@return Returns details related to as user. Users is a mapping which returns usdInvested, tokensAllocated, referrerEarnings, tokensClaimed.
 */
export const getUserDetails = async (userAddress) => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let userDetails = await presaleContract.users(userAddress);
    return userDetails;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/**
 *@dev Function to get status of the presale.
 *@return Returns whether presale is ended or not. True if it is ended
 */
export const isPresaleEnded = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let status = await presaleContract.presaleEnded();
    return status;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/**
 *@dev Function to get status of the referrer bonus.
 *@return Returns whether Referrer Bonus Released is true or fasle. If it is true then user can claim referrer bonus.
 */
export const isReferrerBonusReleased = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let status = await presaleContract.referrerBonusReleased();
    return status;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/**
 *@dev Function to get status of the tokens sold.
 *@return Returns whether claim Released is true or fasle. If it is true then user can claim the tokens sold.
 */
export const isClaimReleased = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let status = await presaleContract.claimReleased();
    return status;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/**
 *@dev Function to get the latest price of ETH/BNB in USD.
 *@return Returns latest price of ETH/BNB in USD in 18 decimals.
 */
export const getLatestPriceOfETH = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let ethPrice = await presaleContract.getLatestPrice();
    return ethPrice;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

/**
 *@dev Function to get the vesting details.
 *@return Returns vestingStartTime, vestingDuration and cliffDuration.
 */
export const getVestingDetails = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let vestingStartTime = await presaleContract.vestingStartTime();
    let vestingDuration = await presaleContract.vestingDuration();
    let cliffDuration = await presaleContract.cliffDuration();
    return vestingStartTime, vestingDuration, cliffDuration;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const switchNetwork = async (network) => {
  // console.log("switchNetwork", { network });
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: network.chainId }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        // console.log("switchNetwork error catch", switchError.code);
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [network],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
    // console.log(switchError);
  }
};

export const getTotalSupply = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    const tokenAddress = await presaleContract.methods.token().call();

    const tokenContractInstance = new web3.eth.Contract(
      TOKEN_ABI,
      tokenAddress
    );
    if (!tokenContractInstance) throw new Error("Invalid contract");

    const totalSupply = await tokenContractInstance.methods
      .totalSupply()
      .call();

    return totalSupply;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const getWalletBalance = async (owner, token, chainId) => {
  try {
    if (!owner || !token) return "";
    if (token === ZERO_ADDRESS) {
      // const balance = await web3.eth.getBalance(owner);
      const balance = await provider.getBalance(owner);
      // const chainId = await web3.eth.getChainId();
      const network = NETWORKS.find(
        (obj) => obj.chainId === parseInt(chainId, 10)
      );
      const currencySymbol = network?.nativeCurrency?.symbol;
      const res = formatBalance(balance, 18, false, 6) + " " + currencySymbol;
      return res;
    } else {
      // const tokenContractInstance = new web3.eth.Contract(TOKEN_ABI, token);
      const tokenContractInstance = new Contract(token, TOKEN_ABI, signer);
      if (!tokenContractInstance)
        // throw new Error("Invalid contract");
        return;
      const balance = await tokenContractInstance.balanceOf(owner);
      const decimals = await tokenContractInstance.decimals();
      const symbol = await tokenContractInstance.symbol();
      // console.log(
      //   `formatBalance(balance, decimals)`,
      //   formatBalance(balance, decimals)
      // );
      // console.log(
      //   `formatBalance(balance, decimals, true)`,
      //   formatBalance(balance, decimals, true)
      // );
      const res = formatBalance(balance, decimals, true, 6) + " " + symbol;
      return res;
    }
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const getBalance = async (owner, token) => {
  try {
    if (!owner || !token) return "";
    if (token === ZERO_ADDRESS) {
      // const balance = await web3.eth.getBalance(owner);
      const balance = await provider.getBalance(owner);
      const res = formatBalance(balance, 18, false, 6);
      return res;
    } else {
      // const tokenContractInstance = new web3.eth.Contract(TOKEN_ABI, token);
      const tokenContractInstance = new Contract(token, TOKEN_ABI, signer);
      if (!tokenContractInstance) throw new Error("Invalid contract");
      const balance = await tokenContractInstance.balanceOf(owner);
      const decimals = await tokenContractInstance.decimals();
      const res = formatBalance(balance, Number(decimals), false, 6);
      return res;
    }
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const getUserData = async () => {
  try {
    if (!account) return null;
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    const userData = await presaleContract.methods.users(account).call();

    return userData;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const formatContractAddress = (address) => {
  if (address === "" || !web3 || !web3.utils.isAddress(address)) {
    return "";
  }

  const start = address.substring(0, 9); // First 6 characters + '0x'
  const end = address.slice(-3); // Last 3 characters

  return `${start}...${end}`;
};

export const formatBalance = (
  balance,
  decimals,
  toLocalStr = false,
  fixed = 0
) => {
  let res = balance;
  if (decimals) {
    res = Number(ethers.formatUnits(balance, decimals));
  }
  if (fixed > 0) {
    res = Number(res).toFixed(fixed);
  }
  if (toLocalStr) {
    res = Number(res).toLocaleString();
  }
  return res;
};

export const getNetwork = async () => {
  try {
    const chainId = await web3.eth.getChainId();
    const network = NETWORKS.find(
      (obj) => obj.chainId === parseInt(chainId, 10)
    );
    if (network) {
      return network;
    } else {
      throw new Error("Invalid network");
    }
  } catch (err) {
    // console.log("Error", err);
    // throw err;
  }
};

export const getChainId = async () => {
  try {
    const chainId = await web3?.eth?.getChainId();
    return Number(chainId);
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};

export const clearAccount = () => {
  account = undefined;
  // web3.eth.accounts.wallet.clear(); // TODO: need to check
  account = undefined;
  presaleContract = undefined;
  signer = undefined;
  provider = undefined;
};

/**
 *@dev Function to get the referrer bonus percentage.
 *@return Returns the referrer bonus percentage.
 */
export const getReferrerBonusPercentage = async () => {
  try {
    // Contract instance
    // const presaleContract = await getPresaleContract();
    if (!presaleContract) throw new Error("Invalid contract");

    // Call a function of the contract
    let percentage = await presaleContract.referrerBonusPercentage();
    // Contract cannot store decimals, so we need to divide it by 10000
    percentage = Number(percentage) / 10000;
    // To display it in percentage form on UI, we need to multiply it by 100
    percentage = percentage * 100;
    return percentage;
  } catch (err) {
    // console.log("Error", err);
    throw err;
  }
};
