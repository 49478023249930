import { Modal } from "antd";
import claimImage from "../../assets/images/claim.svg";
import { claimableTokens, formatBalance } from "web3-module/presale.web3";
import "./styles.css";
import { useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { convertValueFromContract } from "utils/functions";

const ClaimStartModal = ({
  modalOpen,
  setModalOpen,
  ozBalance,
  alreadyClaimed,
  isClaimTokens,
  referralEarning,
  onOk,
  onCancel,
  handleClaim,
  ...rest
}) => {
  const { address } = useWeb3ModalAccount();
  const [claimableAmount, setClaimableAmount] = useState(null);

  useEffect(() => {
    (async () => {
      if (isClaimTokens) {
        try {
          const res = await claimableTokens(address);
          const _claimableTokensAmount = convertValueFromContract(res, 18);
          setClaimableAmount(_claimableTokensAmount);
        } catch (err) {
          console.error("Error in claimableTokens:::", err);
        }
      } else {
        if (alreadyClaimed > 0) {
          setClaimableAmount(0);
        } else {
          setClaimableAmount(referralEarning);
        }
      }
    })();
  }, [isClaimTokens, referralEarning, alreadyClaimed]);

  return (
    <Modal
      open={modalOpen}
      onOk={onOk}
      onCancel={onCancel}
      title="Claim Token"
      rootClassName="buy-token-modal"
      centered
      destroyOnClose={false}
      footer={false}
      maskClosable={false}
      {...rest}
    >
      <div className="flex flex-col items-center gap-5">
        <img src={claimImage} alt="icon" />
        <div className="w-[70%] flex items-center justify-between">
          <p className="text-md text-[#FFFFFF80] leading-none text-center">
            Total Tokens
          </p>
          <p className="text-[16px] text-[#FFFFFF] font-medium leading-none">
            {`${formatBalance(ozBalance, 0, true, 3)} OZ`}
          </p>
        </div>
        <div className="w-[70%] flex items-center justify-between">
          <p className="text-md text-[#FFFFFF80] leading-none text-center">
            Total Claimed
          </p>
          <p className="text-[16px] text-[#FFFFFF] font-medium leading-none">
            {`${formatBalance(alreadyClaimed, 0, true, 3)} OZ`}
          </p>
        </div>
        <p className="text-md text-[#FFFFFF80] leading-none text-center">
          Available to be claimed
        </p>
        <p className="text-[32px] text-[#FFFFFF] font-medium leading-none">
          {`${formatBalance(claimableAmount, 0, true, 3)} OZ`}
        </p>
        {/* <p className="text-xs text-[#FFFFFF80] leading-none text-center">
          ${claimableAmount}
        </p> */}

        <button
          className="text-[#000000] text-base font-medium py-4 w-full bg-[#ffffff] border border-[#FFFFFF0F] rounded-full hover:bg-[#ffffffcc] disabled:opacity-80"
          onClick={handleClaim}
          disabled={!claimableAmount}
        >
          Claim
        </button>
      </div>
    </Modal>
  );
};

export default ClaimStartModal;
