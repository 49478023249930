/* eslint-disable */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { formatBalance } from "web3-module/presale.web3";
import cn from "classnames";
import { POLLING_INTERVAL, TEST_NETWORKS } from "utils/constants";
import TransactionCard from "components/transaction/TransactionCard";
import arrowUpRightImage from "../assets/images/arrowupright.svg";
import { getTransactionHistory } from "services/api";
import fileImage from "../assets/images/files.svg";
import { toTxArray } from "utils/functions";
import { useAuth } from "contexts/AuthContext";

const TransactionPage = ({ isFullPage = true }) => {
  const navigate = useNavigate();
  const [txArray, setTxArray] = useState([]);
  const { address } = useWeb3ModalAccount();
  const { fetchLatestNotifTxnData, updateFetchLastestNotifTxnData } = useAuth();

  const handleClickCard = () => {
    if (!isFullPage) navigate("/transactions");
  };

  const totalTxAmount = () => {
    let res = 0;
    for (const e of txArray) {
      res += Number(e.value);
    }
    return formatBalance(res, 0, true, 3);
  };

  const updateData = async () => {
    if (!address) return;
    try {
      const data = await getTransactionHistory(address);
      const sortedData = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      // console.log("tx history:::", sortedData);
      setTxArray(toTxArray(sortedData));
      updateFetchLastestNotifTxnData(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (address || fetchLatestNotifTxnData)
      updateData().catch((err) => console.error(err));
  }, [address, fetchLatestNotifTxnData]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateData().catch((err) => console.error(err));
    }, POLLING_INTERVAL / 2);
    return () => clearInterval(interval);
  }, [address]);

  const handleSeeAll = () => {
    navigate("/transactions");
  };

  return (
    <div
      className={cn("w-full h-full", {
        "bg-none": isFullPage,
        "bg-[#FFFFFF0A] rounded-[24px] p-6 border border-[#FFFFFF0F] min-h-[350px]":
          !isFullPage,
      })}
      // onClick={handleClickCard}
    >
      <div className="flex justify-between items-center text-[24px]">
        {isFullPage ? (
          <p>My Transactions</p>
        ) : (
          <p
            className={cn("", {
              "mb-[6px]": !txArray.length === 0,
              "mb-[20px]": txArray.length === 0,
            })}
          >
            Transaction History
          </p>
        )}
        {isFullPage && (
          <div className="flex gap-6">
            <div className="flex gap-3">
              <div className="bg-[#78CB5E] rounded-full w-4 h-4"></div>
              <p className="text-base leading-none text-[#FFFFFF99]">
                Total Completed Transaction
              </p>
              <p className="text-base leading-none">{txArray.length}</p>
            </div>
            <div className="flex gap-3">
              <div className="bg-[#78CB5E] rounded-full w-4 h-4"></div>
              <p className="text-base leading-none text-[#FFFFFF99]">
                Total Transaction Amount
              </p>
              <p className="text-base leading-none">${totalTxAmount()}</p>
            </div>
          </div>
        )}
      </div>
      {txArray.length > 0 ? (
        isFullPage ? (
          txArray.map((item, index) => (
            <TransactionCard key={index} txHistory={item} isFullPage={true} />
          ))
        ) : (
          txArray
            .slice(0, 2)
            .map((item, index) => (
              <TransactionCard
                key={index}
                txHistory={item}
                isFullPage={false}
              />
            ))
        )
      ) : (
        <div
          className={cn(
            "flex flex-col justify-center items-center gap-3 rounded-[8px] h-[calc(100%-54px)]",
            {
              "mt-[10%]": isFullPage,
              "": !isFullPage,
            }
          )}
        >
          <img src={fileImage} alt="file" />
          <p className="text-base text-[#FFFFFFCC] leading-none font-normal">
            No Transactions Yet
          </p>
          <p className="text-xs text-[#FFFFFF66] leading-none font-normal">
            After your first tranasaction you will be able to view it here
          </p>
        </div>
      )}

      {!isFullPage && txArray.length > 0 && (
        <div className="w-full flex justify-between items-center">
          <p className="text-lg font-medium">See All Transactions</p>
          <button
            className="p-2 flex justify-center items-center rounded-full bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] border-[#FFFFFF0F]"
            onClick={handleSeeAll}
          >
            <img src={arrowUpRightImage} alt="arrow" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TransactionPage;
