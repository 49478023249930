const BulletRightButton = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9463 12L8.87301 7.92701C8.73468 7.78851 8.66385 7.61443 8.66051 7.40476C8.65735 7.19526 8.72818 7.01801 8.87301 6.87301C9.01801 6.72818 9.19368 6.65576 9.40001 6.65576C9.60634 6.65576 9.78201 6.72818 9.92701 6.87301L14.4213 11.3673C14.5148 11.4609 14.5808 11.5597 14.6193 11.6635C14.6578 11.7673 14.677 11.8795 14.677 12C14.677 12.1205 14.6578 12.2327 14.6193 12.3365C14.5808 12.4403 14.5148 12.5391 14.4213 12.6328L9.92701 17.127C9.78851 17.2653 9.61443 17.3362 9.40476 17.3395C9.19526 17.3427 9.01801 17.2718 8.87301 17.127C8.72818 16.982 8.65576 16.8063 8.65576 16.6C8.65576 16.3937 8.72818 16.218 8.87301 16.073L12.9463 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BulletRightButton;
