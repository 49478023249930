import React, { ReactNode, forwardRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";

// type ButtonVariant =
//   | "default"
//   | "main"
//   | "secondary"
//   | "gradient"
//   | "link"
//   | "link"
//   | "danger";
// type ButtonSize = "sm" | "lg";

const Button = forwardRef(
  (
    {
      className,
      children,
      href,
      variant = "default",
      size = "sm",
      loading,
      rounded,
      ...props
    },
    ref
  ) => {
    if (href && variant === "link") {
      return (
        <Link
          to={props.disabled ? "#" : href}
          className={classNames(
            styles.default,
            styles[variant],
            styles[size],
            {
              [styles.disabled]: props.disabled,
            },
            className
          )}
          style={props.style}
          ref={ref}
        >
          {children}
        </Link>
      );
    }
    return (
      <button
        className={classNames(
          styles.default,
          styles[variant],
          styles[size],
          {
            [styles.rounded]: rounded,
          },
          {
            [styles.loading]: loading,
          },
          className
        )}
        ref={ref}
        {...props}
        {...((props.disabled || loading) && {
          onClick: (e) => {
            e.preventDefault();
          },
        })}
      >
        {loading && <span>is loading</span>}
        {!loading && children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
