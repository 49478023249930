import {
  API_BASE_ROUTE,
  INFURA_ETHEREUM_KEY,
  PRESALE_ETHEREUM,
  PRESALE_SEPOLIA,
  SEPOLIA_RPC_URL,
} from "../utils/config/app.config";

export const HTTP_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER: 500,
  NOT_IMPLEMENTED: 501,
};

export const TX_MODAL_NAME = {
  start: "StartModal",
  progress: "ProgressModal",
  success: "uccessModal",
  fail: "FailModal",
  changeNetwork: "ChangeNetworkModal",
};

export const OZ_PRICE_PHASE = {
  phase01: 0.001,
  phase02: 0.002,
  phase03: 0.003,
  phase04: 0.004,
  phase05: 0.005,
  phase06: 0.006,
  phase07: 0.007,
  phase08: 0.008,
  phase09: 0.009,
  phase10: 0.01,
  phase11: 0.012,
  phase12: 0.014,
  phase13: 0.016,
  phase14: 0.018,
  phase15: 0.02,
  phase16: 0.022,
  phase17: 0.024,
  phase18: 0.026,
  phase19: 0.028,
  phase20: 0.03,
  listing: 0.05,
  target: 1.0,
  moon: 10.0,
};

export const OZ_PRICE_PHASES = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  NINETEEN: 19,
  TWENTY: 20,
};

export const OZ_PHASE_TO_PRICE_MAPPING = {
  1: 0.001,
  2: 0.002,
  3: 0.003,
  4: 0.004,
  5: 0.005,
  6: 0.006,
  7: 0.007,
  8: 0.008,
  9: 0.009,
  10: 0.01,
  11: 0.012,
  12: 0.014,
  13: 0.016,
  14: 0.018,
  15: 0.02,
  16: 0.022,
  17: 0.024,
  18: 0.026,
  19: 0.028,
  20: 0.03,
  listing: 0.05,
  target: 1.0,
  moon: 10.0,
};

export const CONTRACT_DECIMAL = 6;
export const LAUNCH_PRICE = 10;

export const URL_CONFIG = {
  register: `register`,
  updateUser: `updateUser`,
  login: `${API_BASE_ROUTE}/login`,
  createTransactionActivity: `createTransactionActivity`,
  getTransactionHistory: `getTransactionHistory`,
  getUserInfo: `getUserInfo`,
  getReferrerAddress: `getReferrerAddress`,
  updateReferral: "updateReferral",
  getUsersRanking: `getUsersRanking`,
  createNotification: `createNotification`,
  fetchNotification: `fetchNotification`,
  markAllNotificationsAsRead: `markAllNotificationsAsRead`,
  markNotificationAsRead: `markNotificationAsRead`,
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const defaultBreakpoints = {
  miniMobile: 320,
  mobile: 576,
  tablet: 960,
  screen: 1200,
};

export const BASE_COLOR = "#ffffff5a";
export const HIGHLIGHT_COLOR = "#ffffff1a";

export const POLLING_INTERVAL = 10000; // polling interval is 10s

export const PRESALE_CONTRACTS = [
  {
    chainName: "Sepolia",
    chainId: 11155111,
    address: PRESALE_SEPOLIA,
    explorerUrl: "https://sepolia.etherscan.io",
  },
  {
    chainName: "Ethereum",
    chainId: 1,
    address: PRESALE_ETHEREUM,
    explorerUrl: "https://etherscan.io",
  },
  // {
  //   chainName: "BSC testnet",
  //   chainId: 97,
  //   address: "0x11C639AC9aC8f4424B10C511E6a0A3737a23D420",
  // },
  // {
  //   chainName: "Fuji",
  //   chainId: 43113,
  //   address: "0xF476e79855D0C8dC25A31C2D97639DdbE6E774d1",
  // },
];

export const TOKENS = [
  // test net
  {
    tokenSymbol: "ETH",
    chainName: "Sepolia",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    decimal: 18,
    chainId: 11155111,
  },
  {
    tokenSymbol: "USDT",
    chainName: "Sepolia",
    tokenAddress: "0x83F1877569A173da541B5888A33d2747b517134F",
    decimal: 6,
    chainId: 11155111,
  },
  {
    tokenSymbol: "USDC",
    chainName: "Sepolia",
    tokenAddress: "0x1428805025084Dfb1a38303861D61A8Cc6C1E355",
    decimal: 6,
    chainId: 11155111,
  },
  // {
  //   tokenSymbol: "BNB",
  //   chainName: "BSC testnet",
  //   tokenAddress: "0x0000000000000000000000000000000000000000",
  //   decimal: 18,
  //   chainId: 97,
  // },
  // {
  //   tokenSymbol: "USDT",
  //   chainName: "BSC testnet",
  //   tokenAddress: "0x5AA25DF6045aD3139a286b28f761Db9f4325737A",
  //   decimal: 6,
  //   chainId: 97,
  // },
  // {
  //   tokenSymbol: "AVAX",
  //   chainName: "Fuji",
  //   tokenAddress: "0x0000000000000000000000000000000000000000",
  //   decimal: 18,
  //   chainId: 43113,
  // },
  // {
  //   tokenSymbol: "USDT",
  //   chainName: "Fuji",
  //   tokenAddress: "0x8EdFF03074793CCE657AB7560CADB41Fa1F77690",
  //   decimal: 6,
  //   chainId: 43113,
  // },
  // {
  //   tokenSymbol: "USDC",
  //   chainName: "Fuji",
  //   tokenAddress: "0xa4A1992246Fe260E144B54fD8518e7124e19F353",
  //   decimal: 6,
  //   chainId: 43113,
  // },
  // live net
  {
    tokenSymbol: "ETH",
    chainName: "Ethereum",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    decimal: 18,
    chainId: 1,
  },
  // {
  //   tokenSymbol: "BNB",
  //   chainName: "BSC",
  //   tokenAddress: "0x0000000000000000000000000000000000000000",
  //   decimal: 18,
  //   chainId: 56,
  // },
  {
    tokenSymbol: "USDT",
    chainName: "Ethereum",
    tokenAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    decimal: 6,
    chainId: 1,
  },
  {
    tokenSymbol: "USDC",
    chainName: "Ethereum",
    tokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    decimal: 6,
    chainId: 1,
  },
  // {
  //   tokenSymbol: "MATIC",
  //   chainName: "Polygon",
  //   tokenAddress: "0x0000000000000000000000000000000000000000",
  //   decimal: 18,
  //   chainId: 137,
  // },
  // {
  //   tokenSymbol: "AVAX",
  //   chainName: "Avalanche",
  //   tokenAddress: "0x0000000000000000000000000000000000000000",
  //   decimal: 18,
  //   chainId: 43114,
  // },
  // {
  //   tokenSymbol: "USDC",
  //   chainName: "BSC",
  //   tokenAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  //   decimal: 6,
  //   chainId: 56,
  // },
];

export const TEST_NETWORKS = [
  {
    chainId: 11155111,
    chainName: "Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc2.sepolia.org"],
    blockExplorerUrls: ["https://sepolia.otterscan.io"],
  },
  // {
  //   chainId: 97,
  //   chainName: "BSC testnet",
  //   nativeCurrency: {
  //     name: "BNB",
  //     symbol: "BNB",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
  //   blockExplorerUrls: ["https://testnet.bscscan.com/"],
  // },
  // {
  //   chainId: 43113,
  //   chainName: "Fuji",
  //   nativeCurrency: {
  //     name: "AVAX",
  //     symbol: "AVAX",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://avalanche-fuji-c-chain-rpc.publicnode.com"],
  //   blockExplorerUrls: ["https://testnet.snowtrace.io"],
  // },
  // {
  //   chainId: "0x13882",
  //   chainName: "Amoy Testnet",
  //   nativeCurrency: {
  //     name: "MATIC",
  //     symbol: "MATIC",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://rpc-amoy.polygon.technology"],
  //   blockExplorerUrls: ["https://www.oklink.com/polygonAmoy"],
  // },
];
// Live networks
export const NETWORKS = [
  {
    chainId: 1,
    chainName: "Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [INFURA_ETHEREUM_KEY],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  {
    chainId: 11155111,
    chainName: "Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [SEPOLIA_RPC_URL],
    blockExplorerUrls: ["https://sepolia.etherscan.io"],
  },
  // {
  //   chainId: 56, // 56 in decimal
  //   chainName: "BSC",
  //   nativeCurrency: {
  //     name: "BNB",
  //     symbol: "BNB",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://rpc.ankr.com/bsc"],
  //   blockExplorerUrls: ["https://bscscan.com"],
  // },
  // {
  //   chainId: 43114, // 43114 in decimal
  //   chainName: "Avalanche Mainnet C-Chain",
  //   nativeCurrency: {
  //     name: "Avalanche",
  //     symbol: "AVAX",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
  //   blockExplorerUrls: ["https://snowtrace.io/"],
  // },
  // {
  //   chainId: 137, // 137 in decimal
  //   chainName: "Polygon Mainnet",
  //   nativeCurrency: {
  //     name: "MATIC",
  //     symbol: "MATIC",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://endpoints.omniatech.io/v1/matic/mainnet/public"],
  //   blockExplorerUrls: ["https://polygonscan.com/"],
  // },
];
