/* eslint-disable */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import ozakLogo from "../assets/images/ozak-logo.svg";
import walletImage from "../assets/images/wallet.svg";
import bg from "../assets/images/background.svg";
import Button from "components/button/Button";
import MetamaskIcon from "../assets/images/metamask-logo.svg";
import BulletRightButton from "components/svgButton/BulletRightButton";
import { shortenAddr } from "utils/functions";

const HomePage = () => {
  const { open, close } = useWeb3Modal();
  const navigate = useNavigate();
  const { address, chainId, isConnected, status } = useWeb3ModalAccount();

  const handleGoHome = () => {
    window.location.href = "https://www.ozak.ai/";
  };

  const handleConnectWallet = () => {
    open();
  };

  useEffect(() => {
    if (!address) close();
  }, []);

  useEffect(() => {
    if (isConnected) {
      navigate("/buy");
    }
    // setIsConnecting(false);
  }, [isConnected]);

  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#000",
      }}
    >
      {/* <video
        autoPlay="autoplay"
        loop="loop"
        muted
        playsInline
        className="video-content -z-1 w-full h-screen object-cover"
      >
        <source src={bgVideo} type="video/webm" />
        Your browser does not support the video tag.
      </video> */}
      <div className="w-[600px] h-[450px] bg-black rounded-3xl p-6 flex flex-col justify-center items-center gap-8">
        <div className="flex justify-center items-center gap-4">
          <img src={ozakLogo} alt="logo" width={40} height={40} />
          <p className="text-[30px]">OZAK AI</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-[32px] font-medium inline-block">Welcome to</p>
          <p className="text-[36px] font-medium inline-block">
            OZAK AI Dashboard
          </p>
        </div>
        <p className="text-base text-[#FFFFFF99] text-center font-normal inline-block">
          To reach dashboard connect your wallet first!
        </p>
        <div className="flex justify-center items-stretch gap-8 h-[44px]">
          <Button
            variant="secondary"
            rounded
            onClick={handleGoHome}
            className="!h-full"
          >
            Go Home
          </Button>
          <Button
            variant="main"
            className="!rounded-[10px] !px-4 !py-3 !h-auto border border-[#FFFFFF0F]"
            onClick={handleConnectWallet}
          >
            {!isConnected ? (
              // && !isConnecting
              <>
                <img
                  src={walletImage}
                  alt="wallet"
                  width={20}
                  height={18}
                  className="mr-2"
                />
                Connect wallet
              </>
            ) : (
              // !isConnected && isConnecting ? (
              //   <>
              //     <img
              //       src={spinnerBlack}
              //       alt="spinner"
              //       width={20}
              //       height={18}
              //       className="mr-2"
              //     />
              //     Connecting...
              //   </>
              // ) :
              <div className="flex justify-between items-center w-max">
                <div className="flex justify-center items-center gap-2">
                  <img src={MetamaskIcon} className="w-[24px]" alt="metamask" />
                  <p className="text-black font-normal">
                    {shortenAddr(address)}
                  </p>
                </div>
                <div className="text-black">
                  <BulletRightButton />
                </div>
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
