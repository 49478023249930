import { useState } from "react";
import { Select } from "antd";
import { TOKENS } from "utils/constants";
import "./styles.css";

const images = require.context("../../assets/images/token", true);

const TokenLabel = ({ symbol, chainName }) => (
  <div className="flex gap-4 items-center py-[10px]">
    {symbol === "USDT" || symbol === "USDC" ? (
      chainName.toLowerCase() === "ethereum" ? (
        <img
          src={images(`./${symbol.toLowerCase()}-ethereum.svg`)}
          alt="icon"
          className="w-[24px]"
        />
      ) : (
        <img
          src={images(`./${symbol.toLowerCase()}.svg`)}
          alt="icon"
          className="w-[24px]"
        />
      )
    ) : (
      <img
        src={images(`./${symbol.toLowerCase()}.svg`)}
        alt="icon"
        className="w-[24px]"
      />
    )}
    {/* <img
      src={images(`./${symbol.toLowerCase()}.svg`)}
      alt={symbol}
      className="w-[24px]"
    /> */}
    <p className="text-base font-medium text-white leading-none mr-3">
      {symbol}
    </p>
  </div>
);

// Refactored tokenOptions function
const tokenOptions = (tokens) =>
  tokens.map((token) => ({
    value: token.tokenSymbol, // Using tokenSymbol as a unique value
    label: (
      <TokenLabel symbol={token.tokenSymbol} chainName={token.chainName} />
    ),
  }));

const SelectToken = ({ tokens, setSelectedToken, modalOpen, setModalOpen }) => {
  const [open, setOpen] = useState(false);
  const handleChange = (value) => {
    setSelectedToken(tokens.find((token) => token.tokenSymbol === value));
    setTimeout(() => {
      setModalOpen(false);
    }, 750);
  };

  return (
    <Select
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      rootClassName="select-token"
      onChange={handleChange}
      options={tokenOptions(tokens)}
      placeholder="Search Currencies"
    />
  );
};

export default SelectToken;
