import cn from "classnames";
import { formatContractAddress } from "web3-module/presale.web3";
import { roundValue } from "utils/functions";
import "./styles.css";
import CopyButton from "components/copyButton/CopyButton";
import { PRESALE_CONTRACTS } from "utils/constants";
import { useMemo } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

const images = require.context("../../assets/images/rank/", true);

const RankTable = ({ usersRanking, isFullPage = true }) => {
  // console.log("usersRanking:::", usersRanking);
  const { chainId } = useWeb3ModalAccount();

  const explorerUrl = useMemo(() => {
    return (
      PRESALE_CONTRACTS.find((contract) => contract.chainId === chainId)
        ?.explorerUrl || ""
    );
  }, [chainId]);

  return (
    <div className="w-full h-full rounded-[10px] border border-[#FFFFFF0F] rank-table-container">
      <table
        className={cn("min-w-full text-[#FFFFFFB2] rounded-[10px]", {
          "full-page": isFullPage,
        })}
      >
        <thead className="bg-[#00000033] text-xs rounded-[10px]">
          <tr className="w-full">
            <th className="th-text rounded-tl-[10px] w-[111px]">Rank No</th>
            <th className="th-text w-[149px]">Rank Name</th>
            <th className="th-text w-[222px]">Address</th>
            <th className="th-text rounded-tr-[10px]">Total Transaction</th>
          </tr>
        </thead>
        <tbody className="text-base">
          {usersRanking.map((item, index) => (
            <tr
              key={index}
              className={cn("border-[#FFFFFF0F]", {
                "border-b": index !== usersRanking?.length - 1,
              })}
            >
              <td className="py-4 px-5 w-[111px]">
                <div className="flex justify-start items-center relative">
                  <p
                    className={cn("leading-none text-lg text-white rank-no", {
                      "font-medium": isFullPage,
                      "font-normal": !isFullPage,
                    })}
                  >
                    {item.rankNo}
                  </p>
                  <img
                    src={images(`./${item.rankName.toLowerCase()}.svg`)}
                    alt="rank"
                    width={32}
                    height={32}
                    className="relative left-[-5px] top-[1px] z-[2px]"
                  />
                </div>
              </td>
              <td className="py-4 px-5 w-[149px]">{item.rankName}</td>
              <td className="py-4 px-5 text-[#54A6FF] w-[222px]">
                <div className="flex items-baseline gap-2">
                  <a
                    href={`${explorerUrl}/address/${item.address}`}
                    target="_blank"
                  >
                    {formatContractAddress(item.address)}
                  </a>
                  <CopyButton
                    copystring={item.address}
                    btnClassName="!p-1"
                    imgClassName="w-4 h-4"
                  />
                </div>
              </td>
              <td className="py-4 px-5">
                $ {roundValue(item.totalTransaction, 3)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RankTable;
