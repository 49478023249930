import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import { useMediaQuery } from "react-responsive";
import { Header } from "components/layout/Header";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { initializeWeb3, setAccount, setWeb3 } from "web3-module/presale.web3";

const MenuLayout = () => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { isConnected, address, status, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();

  useEffect(() => {
    if (status !== "reconnecting" && !isConnected) {
      open();
    } else if (chainId && walletProvider) {
      // initializeWeb3(chainId, walletProvider);
    }
  }, [isConnected, address, status]);

  return (
    <div className="flex w-full h-[100vh] overflow-hidden">
      <NavBar />
      <div className="overflow-y-auto w-full h-full min-h-full">
        <Header />
        <div className="w-full p-3 md:p-4 lg:p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MenuLayout;
